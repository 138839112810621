@font-face {
  font-family: 'icomoon';
  src:  url('fonts/fonticon/icomoon.eot?lldsu7');
  src:  url('fonts/fonticon/icomoon.eot?lldsu7#iefix') format('embedded-opentype'),
    url('fonts/fonticon/icomoon.ttf?lldsu7') format('truetype'),
    url('fonts/fonticon/icomoon.woff?lldsu7') format('woff'),
    url('fonts/fonticon/icomoon.svg?lldsu7#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon-files:before {
  content: "\e910";
}
.icon-icon-actualite:before {
  content: "\e90e";
}
.icon-icon-dashboard:before {
  content: "\e90f";
}
.icon-quote-1:before {
  content: "\e90b";
}
.icon-telechargement:before {
  content: "\e90c";
}
.icon-link:before {
  content: "\e90d";
}
.icon-icon-lock:before {
  content: "\e907";
}
.icon-icon-search:before {
  content: "\e908";
}
.icon-icon-date:before {
  content: "\e909";
}
.icon-icon-commandes:before {
  content: "\e90a";
}
.icon-icon-mobile:before {
  content: "\e905";
}
.icon-icon-pin:before {
  content: "\e906";
}
.icon-icon-euro:before {
  content: "\e902";
}
.icon-icon-duree:before {
  content: "\e900";
}
.icon-icon-mail:before {
  content: "\e901";
}
.icon-icon-quotas:before {
  content: "\e903";
}
.icon-icon-user:before {
  content: "\e904";
}
.icon-file:before {
  content: "\e900";
}
.icon-shopping-bag:before {
  content: "\e911";
}
.icon-shopping-cart:before {
  content: "\e912";
}
.icon-info:before {
  content: "\ea0c";
}