@media(min-width:1400px) {

}
/*@media(min-width:768px) {*/
@media(min-width:1200px) {
	/*.sidebar-collapse .main-sidebar .logo>.logo-mini {
		display: block;
	    margin-left: -15px;
	    margin-right: -15px;
	    font-size: 18px;
	}
	.main-sidebar .logo {
		transition:all 0.3s ease;
		visibility:visible;
	}
	.sidebar-collapse .main-sidebar .logo {
		transition:all 0.3s ease, visibility 0s linear 0.3s;
		transform:scale(0);
		visibility:hidden;
	}

	.content-wrapper, 
	.main-footer {
	    margin-left:250px;
	    min-width:740px;
	}
	.main-header .navbar { 
	    padding:0px 50px;
	    margin-left:250px;
	    min-width:740px;
	}*/

	.main-header .sidebar-toggle {
		display:none;
	}
}



@media(max-width:1499px) {
	.quota-inputs > div {
		max-width:150px;
	}
}
@media(max-width:1399px) {
	.days-until > span {
		display:block;
		margin-bottom:10px;
	}
	.until-count > span {
		margin: -3px 1px 0;
	}
	.until-count > span:last-child {
		margin: -3px 4px 0 1px;
	}

	/** Commande **/
	.form-dates {
		margin-right:0;
		margin-left:30px;
		margin-top:10px;
		display:table;
	}
	.form-dates:first-child {
		margin-top:0;
	}
	.form-dates:after {
	    content:"";
	    position:absolute;
	    left:-23px;
	    right:auto;
	    top:50%;
	    margin-top:12px;
	    transform:translateY(0%) rotate(-130deg) scaleX(-1);
	    width: 23px;
	    height: 23px;
	    background: url(../img/icon-to.svg) no-repeat;
	    background-size: 100%;
	}

	/** quotas **/
	.quota-inputs > div {
		max-width:100px;
	}
}

@media(max-width:1299px) {
	.row-overflow {
		overflow:auto;
	}
}

@media(max-width:1250px) {
	.operation-filters .row-filters {
		/*padding-top:45px;*/
		position:relative;
	}
	.switchable-view {
		position:absolute;
		right:0;
		top:0;
	}
	.switchable-view a {
		width: 36px;
    	height: 36px;
   	 	padding: 11px 8px;
	}
	.switchable-view a.large-view {
	    padding: 12px 8px;
	}
}

@media(max-width:1199px) {
	.content-wrapper {
	    padding: 0px 30px 30px;
	}
	.navbar-static-top {
	    padding-right:30px;
	}

	.animation-teaser .col-md-2 {
		width:auto;
		float:none;
		margin:auto;
	}
	.animation-teaser .col-md-6 {
		width:auto;
		margin:auto;
		float:none;
	}
	.animation-teaser .col-md-3 {
		width:100%;
	}
	.animation-info-command {
	    margin: 0px auto 30px;
	}

	/** Commande **/
	.command-filters .form-group {
		width:32%;
	}
	.command-filters .form-group .md .ct-dropdown {
		min-width:0;
		width:100%;
	}
	.table-custom > tbody > tr > td {
		padding:15px 10px;
	}

	/** Quotas **/
	.quota-head,
	.national > .quota-head {
		text-align:left !important;
	}

	.quota-allocate {
		position:relative;
		top:0;
		left:0;
		display:table;
		margin:10px auto 5px;
	}
	.national > .quota-head .quota-allocate {
		margin:25px auto 10px;
	}

	.quota-head .quota-label, 
	.national > .quota-head > span {
		position:static;
		left:0;
		top:0;
		transform:none;
		float:left;
		width:200px;
		margin-right:20px;
	}

	.quota-head .quota-label .ct-qAssigned, 
	.national > .quota-head > span .ct-qAssigned {
		display:block;
	}
 
	.quota-head .quota-inputs,
	.national > .quota-head .quota-inputs {
		
	}

	li.quota-item.niv-0 > .quota-head .quota-btn, 
	li.quota-item.niv-1 > .quota-head .quota-btn {
		transform:none;
		top:15px;
	}

	li.quota-item.niv-1 > .quota-head .quota-inputs {
		padding:0;
	}
}

@media(max-width:1150px) {
	.form-dates {
		/*display: inline-flex;*/
	}
}

@media(max-width:1050px) {
	.ct-total-time {
		margin-right:0;
	}
	.animation-list .animation-head .box-actions {
		position:relative;
		top:0;
		right:auto;
		left:auto;
		margin:auto;
	    /*flex: 1 1 auto;*/
	}
	.animation-head-item {
		flex: 1 1 auto;
	}
}


@media(min-width:768px) and (max-width:1199px) {
	

	.sidebar-mini.sidebar-collapse .main-sidebar {
		z-index:10;
	}

	.brand-sidebar .logo-mini {
		display:none;
	}


	.sidebar-collapse .sidebar-filters {
	    transition:none;
	    transform: translateX(-100%);
	    height:0;
	    padding:0;
	}
	.sidebar-collapse .sidebar-menu > li ul {
	    background-color:#34495E;
	    border-top:1px solid rgba(255,255,255,0.1);
	    margin-top:-1px;
	}

	.sidebar-collapse .sidebar-menu > li > a {
	    padding:6px 10px;
	}

	.sidebar-collapse .sidebar-menu > li.treeview > a:after {
	    transition:all 0.25s ease, visibility 0s linear 0s;
	    visibility:hidden;
	    border:none;
	}

	.sidebar-collapse .sidebar-menu > li > a > i {
	    position:static;
	    margin-top:0;
	}

	.sidebar-collapse .sidebar-menu > li > a > span,
	.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>a>span {
	    margin-left:0;
	    background-color:#34495E;
	}


	.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>a>span {
	    padding:13px 5px 13px 20px;
	}
	.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:first-child:hover>a>span {
	    margin-top:-1px;
	}

	.sidebar-collapse .main-header .sidebar-toggle:before {
	    /*content: "\f08b";
	    transform: rotate(0deg);
	    left:-5px;*/
	}
	.sidebar-collapse .main-header .sidebar-toggle:hover:before {
	    /*left:-0;*/
	}

	.sidebar-collapse .brand-sidebar .logo-lg {
		display:none;
	}
	.sidebar-collapse .brand-sidebar .logo-mini {
		display:block;
	}

	.main-header .sidebar-toggle {
		left:25px;
	}

	.sidebar-collapse .logo-label {
		transform: scale(0);
	}

	.sidebar-collapse .main-sidebar .logo {
		padding:13px 15px;
	}


	.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>a>span:not(.pull-right), 
	.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
    	min-width: 220px;
	}

	.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-white .sidebar-menu>li:hover>a>span {
    	min-width: 0;
    	height:43px;
	}

	.sidebar-mini:not(.sidebar-mini-expand-feature).sidebar-collapse .sidebar-menu>li>.treeview-menu {
		height:auto !important;
		padding:15px 20px !important;
		margin:-1px 0 0 !important;
	}

	.sidebar-collapse .sidebar-menu > li[data-tab="Configuration"] > a > span,
	.sidebar-mini.sidebar-collapse .sidebar-menu>li[data-tab="Configuration"]>.treeview-menu {
		min-width:250px !important;
	}

	.sidebar-mini.sidebar-collapse .sidebar-menu>li>a>span, 
	.sidebar-mini.sidebar-collapse .sidebar-menu>li>.treeview-menu {
		display: block !important;
	    position: absolute;
	    width: 180px;
	    left: 50px;
	    visibility: hidden;
	    transition: opacity 0.3s ease 0s, visibility 0s linear 0.3s;
	    opacity: 0;
	}

	.sidebar-mini.sidebar-collapse .sidebar-menu>li>a>span {
		top: 0;
	    /*margin-left: -3px;*/
	    padding: 13px 5px 13px 20px;
	}

	.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>a>span, 
	.sidebar-mini.sidebar-collapse .sidebar-menu>li:hover>.treeview-menu {
	    visibility: visible;
	    opacity: 1;
	    transition: opacity 0.3s ease 0s, visibility 0s linear 0s;
	}

	.sidebar-collapse .sidebar-menu > li:hover > a {
		background-color: rgba(0,0,0,0.2);
	}
	.sidebar-collapse .sidebar-menu > li:hover > a > span:before {
		content:'';
		z-index:-1;
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		background-color: rgba(0,0,0,0.2);
	}


	.sidebar-collapse .sidebar-white .sidebar-menu > li > a {
		white-space:nowrap;
	}
	.sidebar-mini.sidebar-collapse .sidebar-white .sidebar-menu > li > a span:after {
		margin-right:15px;
	}

	.sidebar-collapse .sidebar-white .sidebar-menu > li:hover > a,
	.sidebar-collapse .sidebar-white .sidebar-menu > li:hover > a > span:before {
		background-color:#ffffff !important;
	}

	.sidebar-collapse .sidebar-footer {
		transform:scale(0);
	}

	.sidebar-menu > li.menu-open.no-color > a {
	    background:transparent;
	}
}



/**** TABLEAUX EN RESPONSIVE *****/


/*
	Max width before this PARTICULAR table gets nasty. This query will take effect for any screen smaller than 760px and also iPads specifically.
	*/
	/*@media
	  only screen 
    and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 1024px)  {*/

@media(max-width:991px) {

	.row-header .header-right {
		float:none;
		display: table;
    	margin: 20px auto 10px;
	}

	.row-header .header_page {
		float:none;
	}

	.row-header .header-right .cta {
		margin:auto;
	}
	.row-footer .cta {
		margin:0 10px;
	}

	.row-header .title-1, 
	.header_page .title-1 {
		text-align:center;
		padding:0;
	}
	.row-header .title-1:before, 
	.header_page .title-1:before {
		display:none;
	}
	.counter {
		display:table;
		margin:15px auto;
	}

	.table-rwd-1 {
		background-color: transparent !important;
		box-shadow:none !important;
	}

	/* Force table to not be like tables anymore */
	.table-rwd-1, 
	.table-rwd-1 > thead, 
	.table-rwd-1 > tbody, 
	.table-rwd-1 > thead > tr > th, 
	.table-rwd-1 > tbody > tr > td, 
	.table-rwd-1 > tbody > tr {
		display: block;
	}

	/* Hide table headers (but not display: none;, for accessibility) */
	.table-rwd-1 > thead > tr {
		position: absolute;
		top: -9999px;
		left: -9999px;
	}

    .table-rwd-1 > tbody > tr {
      margin:0 0 50px;
      background-color:#ffffff;
      box-shadow:0 0 8px 3px rgba(0, 0, 0, 0.04);
      border-left:4px solid #34495E;
    }

    .table-rwd-1 > tbody > tr > td:first-child:after {
		display:none;
	}
	.table-custom > tbody > tr > td:first-child:after {
		display:none;
	}

    .table-rwd-1 > tbody > tr > td {
    	text-align: center;
	}
    
	.table-rwd-1 > tbody > tr > td {
		position:relative;
		/*padding-top:65px !important;*/
	}
	.table-custom > tbody > tr > td {
		border-left:0;
	}

	.table-rwd-1 > tbody > tr > td:before {
	    /* padding: 15px 10px; */
	    /* background-color: #34495E; */
	    /* color: #ffffff; */
	    /* position: absolute; */
	    content:attr(data-content);
		display: block;
	    top: 0;
	    color: #414141;
	    left: 0;
	    width: 100%;
	    font-size: 14px;
	    font-weight: 700;
	    margin-bottom: 5px;
	    text-transform: uppercase;
	}


	/** Tableau des droits rwd **/
	.table-rwd-3 {
		background-color: transparent !important;
    	box-shadow: none !important;
	}
	.table-rwd-3 tr {
		margin:0 0 50px;
		display: flex;
    	flex-wrap: wrap;
    	background-color: #ffffff;
    	box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
	}
	.table-rwd-3 thead tr {
	    position: absolute;
	    top: -9999px;
	    left: -9999px;
	}
	.table-rwd-3 tr td {
		position:relative;
	    flex: 1 1 auto;
	    padding-top:65px !important;
	    
	}
	.table-rwd-3 > tbody > tr > td {
		border-left: 1px solid #e0e0e0;
		text-align:left;
		margin: 0;
	}
	.table-rwd-3 tr td:first-child {
		flex:1 1 100%;
		border-left:0 !important;
		background-color: #34495E;
	}
	.table-rwd-3 td:first-child,
	.table-rwd-3 td.bloc-title {
		padding: 15px !important;
		color:#ffffff;
		text-align:center;
		font-size:18px;
		font-weight:700;
		text-transform: uppercase;
	}
	.table-rwd-3 td.bloc-title .title-2 {
		margin:0;
		/*text-transform: uppercase;*/
	    font-weight: 700;
	    font-size: 18px;
	    /*text-align: center;*/
	    color: #ffffff;
	}

	.table-rwd-3 td:first-child:before,
	.table-rwd-3 td.bloc-title:before {
		display:none;
	}

	.table-rwd-3 td:before {
		content: attr(data-content);
    	display: block;
		background-color:#e0e0e0;
		color:#414141;
		top:0;
		left: -1px;
    	right: 0;
    	width:auto;
    	padding: 15px 10px;
		font-size:13px;
		font-weight: 700;
		position: absolute;
	}

	.table-rwd-3 td:first-child + td ~ td:before {
		border-left: 1px solid #ffffff;
	}


	/**tableau opération rwd **/
	.table-rwd-2 {
		background-color:transparent;
		margin-top:50px;
	}
	.animation-item:nth-child(even) .animation-row .table-custom {
		background-color:transparent;
	}
	.table-rwd-2 tr {
		display:block;
		background-color:#ffffff;
	}

	.table-rwd-2 thead {
		display:none !important;
	}

	.table-rwd-2 tr td {
		display:block;
		width:100% !important;
		text-align:center;
	}


	
	.table-rwd-2 td:before {
	    /* padding: 15px 10px; */
	    /* background-color: #34495E; */
	    /* color: #ffffff; */
	    /* position: absolute; */
	    content:attr(data-content);
		display: block;
	    top: 0;
	    color: #414141;
	    left: 0;
	    width: 100%;
	    font-size: 14px;
	    font-weight: 700;
	    margin-bottom: 5px;
	    text-transform: uppercase;
	}

	.table-rwd-2 td:first-child {
	    background-color: #34495E;
	    color:#ffffff;
	    font-size:16px;
	    padding:10px 15px;
	}
	.table-rwd-2 td:first-child:before {
		display:none;
	}

	/*.table-rwd-1 .box-actions > ul,
	.table-rwd-2 .box-actions > ul*/
	.box-actions > ul {
		right:auto;
		left:50%;
		transform: translateX(-50%);
		margin-top:0;
	}

	/*.table-rwd-1 .box-actions > ul li button,
	.table-rwd-2 .box-actions > ul li button*/
	.box-actions > ul li button,
	.box-actions > ul li a {
		text-align:center;
	}

	.table-sorting-rwd {
		display:none;
	}


	/*** Filtres ***/
	body.filters-open {
		overflow:hidden;
	}

	.cta-filters {
		display:table;
	    /*margin: 20px auto 20px;*/
	    position: fixed;
	    bottom: 0;
	    margin: 0;
	    left: 50%;
	    transform: translateX(-50%);
	    z-index: 10;

		.count {
			font-size: 12px;
			font-weight: 700;
			background-color: white;
			color: #38B66D;
			border-radius: 100%;
			width: 20px;
			height: 19px;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			margin-left: 10px;
		}
	}

	.row-filters.noajax .group-filter-submit {
		display:table;
		margin:20px auto 30px;
	}

	.cta-close-filters {
		width: 40px;
		height: 40px;
		display:table;
		margin: auto auto 0;
		flex: 0 0 auto;
		border-radius: 100%;
		text-indent: -9999px;
		padding: 0;
		text-align: center;
		color: #ffffff;
		box-shadow: 0 1px 6px 0 rgb(0 0 0 / 22%);
		cursor: pointer;
		transition: all 0.2s ease;
	}

	.cta-close-filters:before {
		content: '+';
		text-indent: 0;
		font-size: 24px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
	}

	.group-filters-list {
		background-color: #ffffff;
    	padding: 70px 20px 40px;
    	/*display: flex;
	    flex-direction: column;
	    align-items: center;
	    justify-content: center;*/
		display: flex;
    	flex-direction: column;
    	position: fixed;
	    bottom: 0;
	    top:0;
	    left:0;
	    right:0;
	    overflow:auto;
	    transform: translateY(100%);
	    z-index: 15;
	    box-shadow:0 3px 8px 3px rgba(0, 0, 0, 0.06);
	    transition: all 0.5s ease;
	}
	.filters-open .group-filters-list {
		transform: translateY(0);
	}

	.group-filters-list .form-group {
		display:table;
		margin-left:auto;
		margin-right:auto;
		width:100%;
	}

	.group-filters-list .form-group .select2 {
		width:100% !important;
	}

	.group-filters-list > .row {
		margin:0;
	}

	.operation-filters .row-filters  {
		padding: 0;
	    background: transparent;
	    box-shadow: none;
	}

	.operation-filters .row-filters .form-group,
	.operation-filters .row-filters .form-group-custom {
		display: table;
		margin: 0 auto 10px;
		max-width: 300px;
		width: 100%;
	}

	.row-filters .form-group .ct-dropdown {
		width:100%;
	}

	.switchable-view {
		position: relative;
	}
	.operation-filters .switchable-view {
		margin: -10px 0 -30px;
	}

	.row-filters .filter-reset {
		float:none;
		color:#ffffff;
		background-color:#34495E;
		position: relative;
	    /*display: table;*/
	    display:none;
		flex-direction: row-reverse;
	    font-size: 10px;
    	padding: 8px 15px;
	    border-radius: 3px;
	    font-weight: 700;
	    text-transform: uppercase;
	    text-indent:0;
	    width:fit-content;
	    margin: 20px auto 0;
	    transition: all 0.25s ease;
	}

	.row-filters.filtered .filter-reset {
        display:inline-flex;
    }

	.row-filters .filter-reset i {
		display: inline-block;
	    vertical-align: middle;
	    position: relative;
		top: 2px;
		margin-left: 10px;
	}


	.animation-item:last-child {
		margin-bottom:50px;
	}


	/** Form opération **/
	.dates-inner {
		display:block;
	}
	.dates-group {
		display:block;
		padding:0;
		border:0;
		margin:0 0 30px;
	}


	/** Form commande **/
	.command-shop {
		display:block;
	}
	.command-shop .form-group {
		margin-bottom:30px;
	}

	.command-filters .filter-reset {
		margin-top: 27px;
	}

	.tabs-custom .tab-content {
		padding:0 20px;
	}

	.navigation {
		display:block;
	}
	.btn-actions-ope {
		text-align:center;
	}
	.cta-export {
		display:inline-block;
		margin:15px 5px;
		float:none;
	}

	.cta-export,
	.cta-export:last-of-type {
		display: table;
    	margin: 0 auto 25px;
	}

	.btn-actions-ope .cta-export {
		display:inline-block;
		margin:15px 10px;
	}

	.row-footer .pagination,
	.pagination {
		display:table;
		margin:0 auto 30px;
	}
	.ope-paginate .pagination {
		margin:30px auto 0px;
	}

	.form-dates {
		margin-left:0;
	}

	.table-dates tr:last-child {
		margin-bottom:0;
	}

	.table-dates > tbody > tr > td > div {
		display:table;
		margin:auto;
	}
	.table-dates .profiles {
		width:auto;
		min-width:187px;
		display:table;
		margin:auto;
	}

	.table-dates > tbody > tr > .diff-dates + td {
		padding:0;
	}

	.btn-remove-date {
		display:inline-block;
		margin:0 auto 30px;
	}

	.btn-remove-date.remove-date-0 {
		display:none;
	}

	.footer-actions {
		float:none;
		display:table;
		margin-left:auto;
		margin-right:auto;
	}

	.dates-tabs {
		margin: 15px auto 45px;
	}

	.error-field-alert {
		margin:20px auto 15px;
	}


	/*** Page de quotas ***/
	.quota-btn.all {
		display:table;
	}
	.national > .quota-head .quota-inputs {
		display:block;
	    text-align: center;
	}
	.quota-head .quota-label, 
	.national > .quota-head > span {
		float:none;
	}
	.quota-head .quota-label {
		width:100%;
	}
	.national > .quota-head > span {
		width:auto;
	}

	.quota-head .quota-inputs, 
	.national > .quota-head .quota-inputs {
		width:100%;
		text-align:center;
		margin-top:15px;
	}

	.quota-allocate {
		margin: 15px auto 5px;
	}
}

@media(max-width:991px) {
	.row-filters .form-group-period {
		display:flex;
		max-width: 300px;
		margin-bottom: 10px;
	}
	.row-filters .group-period {
		flex:1 1 auto;
	}
}

@media(max-width:767px) {

	.main-sidebar {
	    -webkit-transform: translate(-250px, 0);
	    -ms-transform: translate(-250px, 0);
	    -o-transform: translate(-250px, 0);
	    transform: translate(-250px, 0);
	}
	.main-header {
	    transition: transform .3s ease-in-out,margin .3s ease-in-out;
	    padding:0;
	}
	.sidebar-open .content-wrapper, 
	.sidebar-open .main-footer, 
	.sidebar-open .main-header {
	    -webkit-transform: translate(250px, 0);
	    -ms-transform: translate(250px, 0);
	    -o-transform: translate(250px, 0);
	    transform: translate(250px, 0);
	}

	.main-header .navbar {
		padding:0 15px;
		text-align:center;
		display:flex;
		align-items:center;
		justify-content:space-between;
	}

	.main-header .sidebar-toggle {
		padding-left:0px;
		left:0;
		position:static;
		transform:none;
	}


	.content-wrapper {
	    padding: 0px 15px 30px;
	}

	.command-filters .form-group,
	.command-filters .form-group select {
		width:100%;
		max-width:none;
	}

	.command-container {
	    padding: 25px 20px;
	}

	.animation-head-wrap {
		display:block;
		padding-bottom:20px;
	}
	.animation-list .animation-head .box-actions {
		margin: 8px auto 0;
	}

	.animation-head-item {
		border-left:0;
		border-top: 1px solid rgba(255,255,255,0.2);
	}

	.animation-head .box-actions {
		top:33px;
	}

	.navbar-item.desktop {
		display:none !important;
	}

	.navbar-item.mobile {
	    display:inline-block;
	    flex:1 1 auto;
	}
	.navbar-item.mobile .logo-label {
		margin:auto;
		font-size: 10px;
	}

	.navbar-custom-menu .dropdown > a:after {
		margin-left:0;
		margin-top: 12px;
	}

	.navbar-custom-menu ul.nav > li > a {
		display:flex;
	}

	.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
		left: 0;
	    min-width: 0;
	    right: 0;
	}

	.user-commands-mobile {
		display:block;
	}

	.dropdown-menu-mobile {
		display:block;
	}
	.dropdown-menu-mobile .ct-dropdown {
		border: 1px solid #d2d6de;
		width:100%;
		height:auto;
	}

	.navbar-custom-menu .select-custom select {
		height:40px;
	}
	.navbar-custom-menu .select-custom:after {
		top:16px;
	    right: 15px;
	}

	.footer-user-more {
		margin-top:20px;
	}
}

@media(max-width:639px) {
	.dates-tabs li a {
		font-size:11px;
		margin:0;
		text-align:center;
	}
	.dates-tabs li a:before {
		left:0;
		right:0;
	}

	.autocomplete-field input {
		height:45px;
	}
	.autocomplete-field .submit-autocomplete-field {
		width:45px;
	}

	.tabs-custom .nav-tabs li a {
		font-size:12px;
		padding:25px 15px 15px;
	}
}

@media(max-width:580px) {
	.title-1,
	.row-header .title-1, 
	.header_page .title-1 {
		font-size:25px;
	}

	.title-3 {
		font-size:20px;
	}

	.title-6 {
		font-size:26px;
	}

	.step-actions {
		text-align:center;
		display:flex;
		flex-direction:column;
	}
	.step-actions a,
	.step-actions button {
		margin: 0 0 20px;
	}

	.command-choice {
		width:100%;
		margin-bottom:30px;
	}
	.command-choice.first .form-group {
		width:100%;
	}

	.step-tabs li {
	    font-size:11px;
	}

	.datepicker-dropdown {
		left:50% !important;
		transform:translateX(-50%);
		max-width:100%;
	}

	
	/*.main-header .sidebar-toggle {
		position:relative;
		left:0;
		top:0;
		transform:none;
	}*/

	.footer-actions {
		width:100%;
	}

	.row-footer .cta, .row-footer button,
	.footer-actions .cta, .footer-actions button {
		width:100%;
		margin:0 0 15px;
	}

	.footer-actions form,
	.row-footer form {
		width:100%;
	}

	/** Quotas **/
	.quota-total {
		text-align:center;
	}
	.quota-total .title-2 {
		font-size:20px;
	}
	.quota-total-flex {
		display:block;
	}
	.quota-total-flex .sep {
		display:none;
	}
	.quota-allocate {
		margin:25px auto 15px;
	}
}

@media(max-width:480px) {
	.dates-group .group-flex,
	.operation-quotas .group-flex {
		display:block;
	}

	.dates-tabs li {
		margin: 0 15px;
    	width: 80px;
	}

	.table-rwd-3 > tbody > tr > td {
		flex:1 1 50%;
	}

	/** Quotas **/
	.national > .quota-head > span {
		width:100%;
		padding:0;
		margin:0;
		text-align:center;
	}

	.quota-btn.all {
	    display: table;
	    float: none;
	    margin: 0 auto 20px;
	}

	.quota-inputs > div {
		max-width:55px;
		margin:0 0 0 10px;
	}
	.national > .quota-head .quota-inputs > div {
		width:70px;
	}

	.quota-head + ul {
		padding:0 10px;
	}

	li.quota-item.niv-1 > ul {
		padding:0;
	}

	.tabs-custom .nav-tabs li a {
		font-size:11px;
		padding:25px 10px 15px;
	}
}

@media(max-width:380px) {
	.dates-tabs li {
		margin:0 5px;
	}

	.pagination span {
	    vertical-align: middle;
	}

	.pagination span a,
	.pagination span.current {
		width:23px;
		height:23px;
		padding: 4px 0;
    	text-align: center;
	}

	.pagination span a {
		display:block;
	}

	.pagination > li > a, 
	.pagination > span > a, 
	.pagination > span.current {
		font-size:11px;
	}
}