/*** Color 1 ***/
.bgcolor-1 {
	background-color:#34495E;
}
.color-1 {
	color:#34495E;
}
.border-color-1 {
	border-color:#34495E;
}

.bgcolor-before-1:before {
	background-color:#34495E;
}
.color-before-1:before {
	color:#34495E;
}
.border-color-before-1:before {
	border-color:#34495E;
}

.bgcolor-after-1:after {
	background-color:#34495E;
}
.color-after-1:after {
	color:#34495E;
}
.border-color-after-1:after {
	border-color:#34495E;
}



/*** Color 2 ***/
.bgcolor-2 {
	background-color:#38B66D;
}
.color-2 {
	color:#38B66D;
}
.border-color-2 {
	border-color:#38B66D;
}

.bgcolor-before-2:before {
	background-color:#38B66D;
}
.color-before-2:before {
	color:#38B66D;
}
.border-color-before-2:before {
	border-color:#38B66D;
}

.bgcolor-after-2:after {
	background-color:#38B66D;
}
.color-after-2:after {
	color:#38B66D;
}
.border-color-after-2:after {
	border-color:#38B66D;
}

.dashboard-choice .familly-choose .switch label:hover:before,
.dashboard-choice .familly-choose .switch input:checked + label:before {
	background-color:#38B66D;
}

.sidebar-white .sidebar-menu .active a i {
    background-color:#38B66D;
}
.sidebar-white .sidebar-menu .active a span:after {
    color:#38B66D;
}