.header-article {
	padding-top:30px;
}
.header-article .link-back {
	font-size:13px;
	font-weight:700;
	text-transform:uppercase;
	color:#ffffff;
	float:left;
}
.header-article .link-back i {
	margin-right:10px;
}

.header-article .link-back:hover,
.header-article .link-back:focus {
	color:inherit;
}

.article-custom {
	background-color:#ffffff;
	border-radius: 5px;
	padding:30px 50px;
	color:#414141;
  	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
@media(max-width:767px) {
	.article-custom {
		padding:30px 25px;
	}
}
@media(max-width:580px) {
	.article-custom {
		margin:0 -15px;
	}
}

.article-head {
	padding:0 0 30px;
	border-bottom:4px solid #d8d8d8;
}

.article-head-flex {
	display:flex;
	justify-content:center;
	align-items:center;
	margin-bottom:20px;
}
.article-head-flex .category {
	margin-right:20px;
}

@media(max-width:580px) {
	.article-head-flex {
		display:block;
	}
	.article-head-flex .category {
		margin:0 auto 10px;
	}
}

.article-title {
	font-size:35px;
	font-weight:600;
	line-height:1.14;
	margin-bottom:20px;
}

@media(max-width:580px) {
	.article-title {
		font-size:30px;
	}
}

.article-summary {
	font-size:16px;
	font-weight:700;
}
@media(max-width:580px) {
	.article-summary {
		font-size:15px;
	}
}

.article-head .teaser-status {
	margin: 20px 0 0;
}

.article-body {
	padding-top:20px;
}

.ck-editor h1 {
	font-size:35px;
	font-weight:600;
	line-height:1.14;
	margin:20px 0;
}
@media(max-width:580px) {
	.ck-editor h1 {
		font-size:30px;
	}
}
.ck-editor h2 {
	font-size:22px;
	font-weight:700;
	line-height: 1.27;
	margin:20px 0;
}
@media(max-width:580px) {
	.ck-editor h2 {
		font-size:20px;
	}
}
.ck-editor h3 {
	font-size:18px;
	font-weight:700;
	line-height: 1.56;
	margin:20px 0;
}
@media(max-width:580px) {
	.ck-editor h3 {
		font-size:16px;
	}
}
.ck-editor p {
	font-size:14px;
	line-height: 1.86;
}

.headline .ck-editor p {
	font-size:14px;
	line-height: 1.57;
}

.ck-editor a {
	color:inherit;
	font-weight:700;
	border-bottom:2px solid #38B66D;
	transition:border 0.15s ease;
}

.ck-editor a:hover,
.ck-editor a:focus {
	border-bottom-width: 4px;
}

.ck-editor a[target="_blank"]:before {
	font-family: 'icomoon';
	content: "\e90d";
	padding-right:5px;
	padding-left:3px;
	border-bottom:7px solid #ffffff;
	color:#38B66D;
}

.ck-editor blockquote {
	position:relative;
	padding:0 50px;
	margin:30px 0;
	font-size:18px;
	line-height:1.44;
	font-weight:600;
	font-style:italic;
	border:none;
}

.ck-editor blockquote:before,
.ck-editor blockquote:after {
	font-family: 'icomoon';
	content: "\e90b";
	position:absolute;
	font-style:normal;
	color:#38B66D;
}
.ck-editor blockquote:before {
	top:0;
	left:0;
}
.ck-editor blockquote:after {
	bottom:0;
	right:0;
	transform:rotate(180deg);
}

.ck-editor blockquote p {
	font-size:18px;
}

@media(max-width:767px) {
	.ck-editor blockquote {
		padding:0 25px;
	}
}
@media(max-width:580px) {
	.ck-editor blockquote {
		padding:0 10px;
	}
}

.ck-editor ul,
.ck-editor ol {
	margin:20px 0;
	padding-left:40px;
}

@media(max-width:767px) {
	.ck-editor ul,
	.ck-editor ol {
		padding-left:25px;
	}
}

.ck-editor ol {
    counter-reset: list;
}

.ck-editor ul li,
.ck-editor ol li {
	position:relative;
	padding-left:20px;
	margin:15px 0;
}

.ck-editor ol li {
	padding-left:30px;
	list-style-type: none;
    counter-increment: list;
}

.ck-editor ul li:before {
	content:'';
	width:8px;
	height:8px;
	border-radius:100%;
	background-color:#38B66D;
	position:absolute;
	left:0;
	top:6px;
}

.ck-editor ol li:before {
	content: counter(list) '.';
	font-size:18px;
	font-weight:700;
	color:#38B66D;
	position:absolute;
	left:0;
	top:-4px;
}

.ck-editor pre {
	margin:20px 0;
}

.article-title-custom {
	font-size:18px;
	font-weight:700;
	line-height: 1.56;
	margin:20px 0;
}

.attachments li a {
	color:#414141;
    display:block;
    padding:20px 30px;
    font-size:16px;
    line-height:1.63;
    font-weight:700;
    background-color:#f3f3f3;
    word-break: break-word;
}
.attachments li a div:before {
	content: "\e90c";
	font-family: 'icomoon';
	margin-right:10px;
	position:relative;
	top:6px;
}
.attachments li a div span {
	display:inline-block;
	margin-top:10px;
	font-size:12px;
	font-weight:700;
	text-transform:uppercase;
	padding-bottom:1px;
	border-width:0 0 2px 0;
	border-style:solid;
}

@media(min-width:992px) {
	.attachments {
	    display:flex;
	    flex-wrap: wrap;
	}

	.attachments li {
		margin-right:15px;
		margin-bottom: 15px;
		max-width:330px;
		max-width:calc(50% - 15px);
	    flex: 1 1 auto;
	}
}
@media(max-width:991px) {
	.attachments li {
		margin:20px 0;
	}
}