html, body {
    font-family: 'Open Sans','Arial','Helvetica',sans-serif;
    margin:0;
    padding:0;
    font-size:14px;
    color:#414141;
}

.cta {
    position:relative;
    display:table;
    padding:12px 25px;
    border-radius:3px;
    font-size:12px;
    font-weight:700;
    text-transform:uppercase;
    overflow:hidden;
    transition:all 0.25s ease;
}
.cta:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    opacity: 0.2;
    transition: all 0.35s cubic-bezier(0.74,0.2,0.51,1.04);
}
.cta:hover,
.cta:focus {
    /*opacity:0.8;*/
}
.cta:hover:after, 
.cta:focus:after {
    right: -100%;
}

button.cta,
input.cta {
    border:0;
    outline:0;
}

.cta-default {
    background-color:#38B66D;
    color:#ffffff;
}
.cta-default:hover,
.cta-default:focus {
    color:#ffffff;
}

#body-authenticate {
	background-color: #ffffff;
}

#background-advertiser-image {
	z-index: -1;
	background-repeat: no-repeat;
	background-size:100%;
	height: 350px;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

#block-image {
	position: absolute; 
	width: 100%; 
	height: 350px;
	background-image:url(../img/visuel-login.jpg);
	background-size:cover;
	background-repeat:no-repeat;
}

.label-animation {
    display:table;
    margin:15px auto 0;
    padding: 2px 15px;
    border-radius:50px;
    color:#ffffff;
    font-size:11px;
    line-height:20px;
    font-weight:700;
    text-transform:uppercase;
    background-color:#38B66D;
    transition:all 0.3s ease 0.1s;
}

.logo-title {
	color: #34495E;
	font-size: 20px;
    font-weight: 700;
}

.login-box-advertiser {
	position: relative;
	background: white;
	width: 400px;
	margin: 0 auto;
	margin-top: 220px;
	border-radius: 3px;
	box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.07);
}

@media(max-width:580px) {
    .login-box-advertiser {
        width:100%;
    }
}

.login-box-body .form-group {
	margin:0px 0 20px;
}

.login-box-body input,
.login-box-body input:focus {
	border:none;
	border-bottom:1px solid #39b66d;
	padding:5px 0 3px 30px;
	background-position:left center;
	background-repeat:no-repeat;
	font-size:12px;
	font-weight:600;
    border-radius: 0;
}

/*.form-username input {
	background-image:url(../img/icon-user.svg);
}
.form-password input {
	background-image:url(../img/icon-lock.svg);
}*/

.login-box-body .form-group {
    position:relative;
}
.login-box-body .form-group:before {
    position:absolute;
    left:0;
    top:3px;
    font-size: 20px;
    font-family: 'icomoon' !important;
    color:#39b66d;
}

.login-box-body .form-group.form-username:before {
    content: "\e904"; 
}
.login-box-body .form-group.form-password:before {
    content: "\e907";
}
.login-box-body .form-group.form-email:before {
    content: "\E901";
}


.login-box-body input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color:#787878 !important;
}
.login-box-body input::-moz-placeholder { /* Firefox 19+ */
  color:#787878 !important;
}
.login-box-body input:-ms-input-placeholder { /* IE 10+ */
  color:#787878 !important;
}
.login-box-body input:-moz-placeholder { /* Firefox 18- */
  color:#787878 !important;
}
.login-box-body input::placeholder {
    color:#787878 !important;
}

.forgot-password {
    margin-top: 10px;
}

.forgot-password,
.back-login {
	font-size:12px;
	font-weight:600;
	color:#34495E;
	display:table;
	transition:all 0.15s ease;
}
.forgot-password:hover,
.forgot-password:focus,
.back-login:hover,
.back-login:focus {
	color:#39b66d;
}
.login-box-body .cta-submit {
	width:100%;
}

.login-error {
    display:table;
    margin:15px auto 0;
    font-weight:bold;
    color:#34495E;
}

.title-reset-password {
    font-size: 14px;
    font-weight: 600;
    margin: 25px 0 20px;
    text-align:center;
    /* padding: 3px 0 3px 12px; */
    /* border-left: 4px solid #38B66D; */
    text-transform: uppercase;
}

.form-user-reset-password form button {
    position: relative;
    display: table;
    margin:auto;
    padding: 12px 25px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    background-color: #38B66D;
    color: #ffffff;
    border:none;
    width: 100%;
    transition: all 0.25s ease;
}
.form-user-reset-password form button:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    opacity: 0.2;
    transition: all 0.35s cubic-bezier(0.74,0.2,0.51,1.04);
}
.form-user-reset-password form button:hover,
.form-user-reset-password form button:focus {
    /*opacity:0.8;*/
}
.form-user-reset-password form button:hover:after, 
.form-user-reset-password form button:focus:after {
    right: -100%;
}

.error-field-alert {
    display:table;
    border-radius:4px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 15px;
    margin-bottom: 15px;
}
.error-field-alert.min {
    padding:10px;
    font-size:13px;
}

.login-box-body.reset-box {
    padding:10px;
}