html, body {
    font-family: 'Open Sans','Arial','Helvetica',sans-serif;
    margin:0;
    padding:0;
    font-size:14px;
    color:#414141;
    height:100% !important;
}

body {
    background-color:#F3F3F3;
}

ul, p, h1,h2,h3,h4,h5 {
    padding:0;
    margin:0;
    font-family: 'Open Sans','Arial','Helvetica',sans-serif;
}
ul { list-style-type:none; }


a {
    transition:all 0.25s ease;
}
a, a:hover, a:focus, a:visited, a:link {
    text-decoration: none;
    outline:none;
}
img {
    border:none;
    max-width:100%;
}
iframe {
    max-width:100%;
}
textarea {
    resize: none;
    color:#555555;
}
select option {
    color:#555555;
}

label.required:after {
    content:'*';
    color:red;
    margin-left:3px;
}
.error-field {
    border-color:red !important;
}
.refrigeratorbin-agency-group.error-field {
    border: 1px solid;
}
.error-field-alert {
    display:table;
    border-radius:4px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 15px;
    margin-bottom: 15px;
}
.error-field-alert.min {
    padding:10px;
    font-size:13px;
}

.container-max {
    max-width:1080px;
    margin:auto;
}

.title-1 {
    font-size:30px;
    font-weight:400;
    margin-bottom:20px;
    position:relative;
    padding-left:30px;
    /*font-family: 'Source Sans Pro', sans-serif;*/
}
.title-1:before {
    content:'';
    display:inline-block;
    vertical-align:middle;
    width:12px;
    height:12px;
    margin-right:10px;
    border-radius:100%;
    position:absolute;
    left:0;
    top:9px;
    background-color:#38B66D;
}

.title-2 {
    font-size:26px;
    font-weight:500;
    margin-bottom:20px;
}

.title-3 {
    font-size:22px;
    font-weight:600;
    margin-bottom:20px;
}

.title-4 {
    font-size:18px;
    font-weight:600;
    margin-bottom:8px;
    position:relative;
    padding-left:20px;
}
.title-4:before {
    content:'';
    display:inline-block;
    vertical-align:top;
    margin-top:3px;
    width:10px;
    height:10px;
    margin-right:10px;
    border-radius:100%;
    position:absolute;
    left:0;
    background-color:#38B66D;
}

.title-5 {
    font-size:20px;
    font-weight:600;
    margin-bottom:15px;
    padding:3px 0 3px 12px;
    border-left:4px solid #38B66D;
}

.title-6 {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.13;
    margin-bottom:20px;
}

.cta {
    position:relative;
    text-align: center;
    display:inline-flex;
    justify-content: center;
    padding:12px 25px;
    border-radius:3px;
    font-size:12px;
    font-weight:700;
    text-transform:uppercase;
    overflow:hidden;
    transition:all 0.25s ease;

    .icon {
        font-size: 13px;
    }

    &.iconL {
        .icon {
            margin-right: 10px;
        }
    }

    &.cta-flex {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: auto;
    }
}

.cta-export {
    margin:20px 0px;
    float: right;
}

.cta-export:last-of-type {
    margin-right: 10px;
    position:relative;
    z-index:1;
}

.navigation {
    display:inline-block;
}
.cta:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    opacity: 0.2;
    transition: all 0.35s cubic-bezier(0.74,0.2,0.51,1.04);
}
.cta:hover,
.cta:focus {
    /*opacity:0.8;*/
}
.cta:hover:after, 
.cta:focus:after {
    right: -100%;
}

button.cta,
input.cta {
    border:0;
    outline:0;
}

.cta-default {
    background-color:#38B66D;
    color:#ffffff;

    &:after {
        background-color: #38B66D;
    }

    &.inverse {
        background-color: white;
        color: #38B66D;
        box-shadow: 0 0 0 1px #38B66D inset;
    }
}
.cta-default:hover,
.cta-default:focus {
    color:#ffffff;

    &.inverse {
        color: #38B66D;
    }
}

.cta-second {
    background-color:#34495E;
    color:#ffffff;
}
.cta-second:hover,
.cta-second:focus {
    color:#ffffff;
}

.cta-green {
    background-color:#95c85d;
    color:#ffffff;
}
.cta-green:hover,
.cta-green:focus {
    color:#ffffff;
}

.cta-grey {
    background-color:#c1c1c1;
    color:#ffffff;
}
.cta-grey:hover,
.cta-grey:focus {
    color:#ffffff;
}

.cta-red {
    background-color:#dd4b39;
    color:#ffffff;
}
.cta-red:hover,
.cta-red:focus {
    color:#ffffff;
}

.cta.min {
    font-size: 10px;
    padding: 8px 15px;
}

.cta-center {
    display:table;
    margin-left:auto;
    margin-right:auto;
}

.link-more {
    color:#34495E;
    border: none;
    margin: 20px 0;
    background: none;
    font-weight:700;
    font-size:11px;
    text-transform: uppercase;
}
.link-more i {
    color: #38B66D;
    position: relative;
    left: 0;
    font-size: 18px;
    vertical-align: top;
    margin: -1px 6px 0 0;
    transition: all 0.15s ease;
}
.link-more:hover,
.link-more:focus {
    color:#38B66D;
}
.link-more:hover i {
    left:3px;
}


.link-back {
    display:table;
    margin: 0px 0px 20px;
    color:#34495E;
    font-weight:700;
    font-size:11px;
    text-transform: uppercase;
}
.link-back i {
    display: inline-block;
    vertical-align: middle;
    color: #ffffff;
    background-color: #38B66D;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size: 15px;
    border-radius: 100%;
    position: relative;
    left: 0;
    margin: -3px 6px 0 0;
    transition: all 0.15s ease;
}

.link-back:hover,
.link-back:focus {
    color:#34495E;
}
.link-back:hover i {
    left:-3px;
}

.row-header .link-back {
    margin:0px 0 30px;
}

.header-operation .link-back {
    margin: 0px 0px 45px;
}


.btn-more {
    text-indent:-9999px;
    display: block;
    width: 34px;
    height: 34px;
    line-height:34px;
    font-size: 28px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    border-radius: 100%;
    position: relative;
    border:none;
    outline:0;
    margin:auto;
}
.btn-more:before {
    content: '+';
    text-indent:0;
    position:absolute;
    left:50%;
    top:50%;
    transform:translateX(-50%) translateY(-50%);
}

.center {
    text-align:center;
}

.nowrap {
    white-space: nowrap;
}

.nofloat {
    float:none;
    margin-left:auto;
    margin-right:auto;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-center {
    justify-content: center;
}

.align-items-center {
    align-items: center;
}

.counter {
    display:inline-block;
    vertical-align:middle;
    margin-left:10px;
    padding:4px 8px;
    border-radius:3px;
    color:#ffffff;
    font-size:14px;
    background-color:#38B66D;
}

.category {
    display:table;
    padding:7px 15px 6px;
    text-transform:uppercase;
    color:#ffffff;
    font-size:12px;
    font-weight:700;
}

.container-custom {
    max-width:1170px;
    padding-left:15px;
    padding-right:15px;
    margin:auto;
}

.w200 {
    max-width: 200px;
}


.dropdown-custom {
    position:relative;
}
.dropdown-head {
    display:block;
    position:relative;
}
.dropdown-head:after {
    content:'';
    border-top:8px solid #ffffff;
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    float:right;
    margin-top:5px;
    transition:all 0.25s ease;
}
.dropdown-custom .dropdown-content {
    display:none;
    position:absolute;
    z-index:1;
    top:100%;
    left:0%;
    min-width:100%;
    padding-top:15px;
}

.dropdown-custom.open .dropdown-head:after {
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    transform:rotate(180deg);
}


/** Switch **/
.switch input,
.form-group input[type="checkbox"] {
    display:none;
}
.form-group .vich-image input[type="checkbox"],
.form-group.file input[type="checkbox"] {
    display: block;
}
.switch label,
.form-group input[type="checkbox"] + label {
    position:relative;
    padding-left:55px;
    cursor:pointer;
    margin:8px 0;
    padding-top:2px;
    font-size:13px;
    font-weight:600;
    text-transform:none;
    color:#414141;
}
.form-group input[type="checkbox"][disabled] + label {
    cursor:not-allowed;
}
.switch label:before,
.form-group input[type="checkbox"] + label:before {
    content:'';
    width: 45px;
    height: 24px;
    border-radius: 14px;
    /*background-color: #46617c;*/
    background-color: #dadada;
    border:1px solid rgba(255, 255, 255, 0.1);
    position:absolute;
    left:0;
    top:0;
    transition:all 0.2s ease;
}
.switch label:after,
.form-group input[type="checkbox"] + label:after {
    content:'';
    width: 16px;
    height: 16px;
    border-radius: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position:absolute;
    left:3px;
    top:4px;
    transition:all 0.2s ease;
}
.switch input:checked + label:before,
.form-group input[type="checkbox"]:checked + label:before {
    background-color:#38B66D;
}
.switch input:checked + label:after,
.form-group input[type="checkbox"]:checked + label:after {
    transform:translateX(22px);
}

.switch.min label {
    padding-left:45px;
}
.switch.min label:before {
    width: 35px;
    height: 20px;
}

.switch.min label:after {
    width: 14px;
    height: 14px;
    left: 3px;
    top: 3px;
}
.switch.min input:checked + label:after {
    transform: translateX(15px);
}

/** Radio button custom **/
.form-group {
    position:relative;
}
.form-group input[type="radio"] {
    display:none;
}
.form-group input[type="radio"] + label {
    position: relative;
    padding-left: 22px;
    margin: 5px 15px 5px 0;
    cursor:pointer;
}
.form-group input[type="radio"][disabled] + label {
    cursor:not-allowed;
}
.form-group input[type="radio"] + label:before {
    /*content: "\f14a";*/
    /*content: "•";*/
    content:'';
    font: normal normal normal 14px/1 FontAwesome;
    /*font-size: 24px;
    line-height: 14px;*/
    position: absolute;
    left: 0;
    top: 2px;
    width: 16px;
    height: 16px;
    border-radius:100%;
    box-shadow: 0 0 0 1px #d4d4d4 inset;
    color: transparent;
    text-align:center;
    overflow:hidden;
    background-color:#ffffff;
}
.form-group input[type="radio"]:checked + label:before {
    /*box-shadow:none;
    color: #ffffff;
    background-color:#38B66D;*/

    box-shadow: 0 0 0 5px #38B66D inset;
}

.label-indent label {
    text-indent:-9999px;
}
.label-indent label:before {
    text-indent:0;
}

.form-ope-inline {
    /*margin-top:18px;*/
}

/** Menu top **/

.navbar-static-top {
    position:relative;
    padding-right: 50px;
}

.main-header {
    z-index:15;
}

.main-header .sidebar-toggle {
    padding:0;
    margin-right: auto;
    font-size:24px;
    position: absolute;
    top: 50%;
    left:0;
    transform: translateY(-50%);
    color:#38B66D;
    padding: 5px 15px 5px 10px;
    /*border-right: 1px solid;*/
}
.main-header .sidebar-toggle:before {
    /*content: "\f090";
    transform: rotate(180deg);
    display: block;
    transition: left 0.2s linear;
    position: relative;
    left: 0;*/
}
.main-header .sidebar-toggle:hover:before {
    /*left:-5px;*/
}

.main-header .sidebar-toggle:hover,
.main-header .sidebar-toggle:focus {
    color:#38B66D;
}

.navbar-item.mobile {
    display:none;
}

.navbar-custom-menu ul.nav > li {
    display:inline-block;
    vertical-align:middle;
    float:none;
    padding:0 15px;
    border-left:1px solid #d4d4d4;
}
.navbar-custom-menu ul.nav > li:first-child {
    border-left:0;
}
.navbar-custom-menu ul.nav > li:last-child {
    padding-right:0;
}

.navbar-custom-menu ul.nav > li > a {
    font-size:13px;
    font-weight:600;
    padding:15px 0;
    color: #414141;
    font-weight:600;
}
.navbar-custom-menu ul.nav > li > a:hover,
.navbar-custom-menu ul.nav > li > a:focus {
    background-color:transparent;
}

.navbar-custom-menu ul.nav > li:first-child > a {
    padding-top:14px;
}

.navbar-custom-menu ul.nav > li > a i {
    display:inline-block;
    margin-right:8px;
    font-size:20px;
    color:#38B66D;
    vertical-align: top;
    margin-top: -1px;
}

.download .icon-icon-commandes::before {
    content: "\e90f";
}

.navbar-custom-menu ul.nav > li > a .user-more {
    position:relative;
    top:2px;
}

.navbar-custom-menu ul.nav > li .select-admin {
    /*margin-top:11px;*/
}
.navbar-custom-menu ul.nav > li .select-admin .dropdown-label {
    font-weight:600;
    font-size:13px;
    color:#414141;
    padding-top:12px;
}

@media(min-width:768px) {
    .navbar-custom-menu ul.nav > li .select-admin .dropdown-label:after {
        top:18px;
    }
} 

.navbar-custom-menu .select-custom select {
    font-weight: 600;
    outline:0;
}

@media(min-width:768px) {
    .navbar-custom-menu .select-custom select {
        border:none;
        color: #414141;
        padding-left:0;
    }
}

.navbar-custom-menu .select-custom:after {
    top: 12px;
    right:0;
}
.navbar-custom-menu .select-custom.open:after {
    top: 12px;
}

.navbar-custom-menu ul.nav li.lang-menu a {
    display:inline-block;
    margin:0 0 0 5px;
}
.navbar-custom-menu ul.nav li.lang-menu a:first-child {
    margin:0;
}
.navbar-custom-menu ul.nav li.lang-menu a {
    text-transform:uppercase;
    font-size:14px;
    color: #34495E;
    font-weight:600;
}
.navbar-custom-menu ul.nav li.lang-menu a.active {
    color:#38B66D;
}

.navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    padding:20px 25px;
    border:none;
    text-align:center;
    min-width:340px;
    box-shadow: 0 3px 8px 3px rgba(0, 0, 0, 0.06);
}
.navbar-custom-menu .dropdown > a:after {
    content: '';
    border-top: 8px solid #38B66D;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    display: inline-block;
    margin-left: 10px;
    position:relative;
    top:3px;
    width: 10px;
    height: 10px;
    transition: all 0.25s ease, visibility 0s linear 0.3s;
}
.navbar-custom-menu .dropdown > a.open:after {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-top: 10px;
}

.navbar-custom-menu .dropdown > a.open + .dropdown-menu {
    display:block;
}

.select-admin .ct-dropdown {
    border:0;
    margin-top:4px;
}
.select-admin .ct-dropdown .dropdown-list {
    min-width:120px;
}

.nav .open>a, .nav .open>a:focus, .nav .open>a:hover {
    background-color:transparent;
}

.menu-user .user-image {
    overflow:hidden;
    float:none;
    display:inline-block;
    vertical-align:middle;
    border-radius:100%;
    text-align: center;
    position: relative;
    top: 3px;
}
.menu-user .user-image.with-img {
    border:2px solid #ffffff;
    width:40px;
    height:40px;
    margin-right:8px;
    background-repeat:no-repeat;
    background-position:center;
    background-size:cover;
}
/*.menu-user .user-image.with-icon:after {
    content:'';
    width:30px;
    height:30px;
    display:block;
    margin-top:-3px;
    background:url(../img/icon-user.svg) no-repeat center;
}*/
.menu-user .user-image i {
    display:none;
}
.navbar-custom-menu .menu-user .user-image.with-icon i {
    display: inline-block;
    margin-top: -3px;
    font-size:22px;
    line-height: 36px;
    text-align: center;
}

.user-more .user-name {
    font-size: 18px;
    font-weight: 600;
    color:#34495E;
}

.user-more .user-info {
    font-size:13px;
    display:table;
    margin:0 auto;
    color:#34495E;
    position:relative;
}
.user-more .user-info.email {
    font-size: 14px;
    font-weight: 600;
    margin:15px auto;
    /*background:url(../img/icon-mail.svg) no-repeat left center;
    padding-left:20px;*/
}
.user-more .user-info.email i {
    font-size: 18px;
    margin-right: 3px;
    display: inline-block;
    vertical-align: top;
    color:#38B66D;
}

.footer-user-more {
    padding:15px 0 0;
    border-top:2px solid #d4d4d4;
}
.footer-user-more .cta {
    margin:0 auto 15px;
}

.menu-logout {
    font-size:12px;
    font-weight:500;
    color:#34495E;
    display:table;
    margin:auto;
}
.menu-logout:hover,
.menu-logout:focus {
    color: #38B66D;
}
.menu-logout i {
    color: #38B66D;
    display: inline-block;
    vertical-align: top;
    margin-right: 5px;
    font-size: 15px;
    margin-top: 1px;
}

.user-commands-mobile {
    font-size: 13px;
    font-weight: 600;
    padding: 15px 0;
    color: #414141;
    font-weight: 600;
    text-align:center;
    display:none;
}

.user-commands-mobile i {
    display: inline-block;
    vertical-align: top;
    margin-top: -3px;
    margin-right: 8px;
    font-size: 20px;
    color: #38B66D;
}

.dropdown-menu-mobile {
    display:none;
}


/** MENU SIDEBAR LEFT **/
.main-sidebar {
    padding-top:0;
    padding-bottom:170px;
    z-index:1050;
    width:250px;
}
.sidebar-header .brand-sidebar {
    background-color:#ffffff;
}
.main-sidebar .logo {
    -webkit-transition: width .3s ease-in-out;
    -o-transition: width .3s ease-in-out;
    transition: width .3s ease-in-out;
    display: block;
    /*min-height:110px;*/
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    padding:20px 15px;
    max-width:100%;
    color:#ffffff;
}
.main-sidebar .sidebar-header .logo {
    color:#34495E;
}

.brand-sidebar h2 {
    font-size: 20px;
    font-weight: 700;
}

.logo-label,
.label-animation {
    display:table;
    margin:15px auto 0;
    padding: 2px 15px;
    border-radius:50px;
    color:#ffffff;
    font-size:11px;
    line-height:20px;
    font-weight:700;
    text-transform:uppercase;
    background-color:#38B66D;
    transform-origin: left;
    transition:all 0.3s ease 0.1s;
}

.sidebar-filters {
    padding:15px 15px;
    width:250px;
    transition:transform 0.3s ease;
}



.sidebar-filters .filter-label {
    text-transform:uppercase;
    color:#ffffff;
    font-size:13px;
    font-weight:700;
    margin-bottom:10px;
}
.main-sidebar .switch label {
    color:#876f97;
    font-size:14px;
}
.main-sidebar .switch label:before {
    background-color: rgba(255,255,255,0.1);
}
.main-sidebar .switch input:checked + label {
    color:#ffffff;
}
.main-sidebar .switch input:checked + label:before {
    color:#ffffff;
}

.sidebar-filters button[type=submit] {
    display:none;
}


/** Switch sidebar **/
.sidebar-white  {
    background-color:#ffffff;
}
.sidebar-white .filter-label {
    color:#414141;
}

.family-choose-sidebar .ct-dropdown {
    width:100%;
    box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.06);
}


.sidebar-menu {
    border-bottom: 1px solid rgba(255,255,255,0.1);
    white-space:normal;
}

.sidebar-white .sidebar-menu {
    border-bottom:1px solid #e0e0e0;
}

.sidebar-menu > li a {
    color:#ffffff;
}

.sidebar-menu > li > a {
    font-size:13px;
    font-weight:700;
    text-transform:uppercase;
    padding:20px;
    padding-left:55px;
    position:relative;
    border-top:1px solid rgba(255,255,255,0.1);
}
.sidebar-menu > li ul.open {
    display:block;
}
.sidebar-menu > li.menu-open > a {
    background-color: rgba(0,0,0,0.2);
}


.sidebar-menu > li > a > i {
    display: inline-block;
    margin-right: 10px;
    width: 30px !important;
    height: 30px;
    line-height: 30px;
    text-align: center;
    font-size:15px;
    border-radius: 100%;
    color:#ffffff;
    background-color: rgba(255,255,255,0.06);
    position: absolute;
    left: 15px;
    top: 50%;
    margin-top: -15px;
}

.sidebar-menu > li ul {
    margin-top: 0;
    padding: 15px 20px;
    /*background-color:#43406B;*/
    background-color:rgba(255,255,255,0.07);
}

.sidebar-menu > li.treeview > a:after {
    content:'';
    border-top:8px solid #38B66D;
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    float:right;
    margin-top:5px;
    transition:all 0.25s ease, visibility 0s linear 0.3s;
}
.sidebar-menu > li.treeview.menu-open > a:after {
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    transform:rotate(180deg);
}


.sidebar-menu > li ul li a {
    position:relative;
    display:block;
    margin:5px 0;
    padding:3px 0;
    color:#9f9f9f;
    font-weight:500;
    font-size:14px;
    padding-left:15px;
    transition:all 0.25s ease;
}
.sidebar-menu > li ul li a:before {
    content: '';
    display: inline-block;
    vertical-align: top;
    width: 6px;
    height: 6px;
    margin-top: 6px;
    background-color:transparent;
    box-shadow: 0 0 0 1px #9fa5b1;
    border-radius: 100%;
    margin-right: 10px;
    position:absolute;
    left:0;
    transition:all 0.25s ease;
}

.sidebar-menu > li ul li a:hover,
.sidebar-menu > li ul li a:focus,
.sidebar-menu > li ul li.active a {
    color:#ffffff;
}
.sidebar-menu > li ul li a:hover:before,
.sidebar-menu > li ul li a:focus:before,
.sidebar-menu > li ul li.active a:before {
    background-color:#38B66D;
    box-shadow: 0 0 0 1px #38B66D;
}


.sidebar-white .sidebar-menu > li > a {
    color:#414141;
    border-top:1px solid #e0e0e0;
}

.sidebar-white .sidebar-menu > li > a i {
    color:#9DA0A5;
    background-color: #f3f3f3;
}

.sidebar-white .sidebar-menu > li > a span:after {
    content: '➜';
    float:right;
    color:#7f8389;
    position:relative;
    left:0;
    transition:all 0.25s ease;
}
.sidebar-white .sidebar-menu > li > a:hover span:after {
    left:5px;
}

.sidebar-white .sidebar-menu .active a i {
    color:#ffffff;
}


.sidebar-footer {
    color:#ffffff;
    font-size:14px;
    text-align:center;
    position:absolute;
    bottom:40px;
    left:0;
    right:0;
    transform-origin:left;
    transition:all 0.3s ease;
}
.sidebar-footer .logo {
    margin:10px auto 0;
    padding-top:0;
    width:100px;
    height:45px;
    background:url(../img/logo-staci-blanc.svg) no-repeat center;
    background-size:100px 45px;
}


/** Sidebar collapse **/



/** Sidebar right **/
.control-sidebar {
    display:none;
}

/** Page content **/
.wrapper {
    overflow-x:auto;
}

.content-header > h1,
.content-header .title-1 {
    font-size:30px;
    margin-bottom: 20px;
    /*color:#2E2C78;*/
    color:#34495E;
}
.content-wrapper {
    /*background-color:#F3F3F3;*/
    background-color:transparent;
    padding:0px 30px 30px;
   
    min-height:0 !important;
}

@media(min-width:1200px) {
    .content-wrapper {
        margin-left:250px;
    }
}

.content-wrapper .content-header {
    padding:10px 0 30px;
    border-bottom: solid 2px #d4d4d4;
}

.row-header {
    padding: 10px 0 20px;
}

.row-header .title-1,
.header_page .title-1 {
    font-size:30px;
    /*color:#2E2C78;*/
    color:#414141;
    margin-bottom:0;
}

.title-type {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
}

.header_page {
    padding: 10px 0 20px;
}

.row-header .header_page {
    float:left;
    padding:0;
}
.row-header .cta-add {
    float:right;
}

.row-header .header-right {
    float:right;
}
.row-header .header-right .cta {
    float:none;
    display:inline-block;
    vertical-align:middle;
    margin:0 0 0 15px;
}

.operation-filters .row-filters {
    /*border-radius: 3px;
    padding:20px 15px 5px 20px;
    background-color: #ffffff;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);*/
    /*padding-right:85px;*/
    margin-bottom:30px;
}
@media(min-width: 992px) {
    .operation-filters .row-filters {
        padding-right: 90px;
        position: relative;
    }
    .operation-filters .row-filters .switchable-view {
        position: absolute;
        right: 0;
    }
}

.row-filters .form-group {
    margin:0;
    position:relative;
}
.row-filters .ct-dropdown {
    /*width:170px;*/
    /*width:210px;*/
    min-width:150px;
    max-width:100%;
    background-color:#ffffff;
}
.row-filters .ct-dropdown .dropdown-label {
    color: #787878;
    font-weight:600;
}
.form-content {
    display:flex;
    justify-content:space-between;
    align-items:center;
}
.form-content .form-group {
    margin-bottom:0;
}

.row-filters [class*=col-]:first-child .form-group:after {
    content:'';

}

.row-filters input[type="text"] {
    /*border:none;
    padding-left:0;
    padding-right:30px;
    font-size:15px;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
    border-radius: 3px;*/
    /*background:url(../img/icon-search.svg) no-repeat right center #ffffff;*/
}

.row-filters input[type="text"]::-webkit-input-placeholder,
.operation-filters input[type="text"]::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color:#787878 !important;
}
.row-filters input[type="text"]::-moz-placeholder,
.operation-filters input[type="text"]::-moz-placeholder { /* Firefox 19+ */
  color:#787878 !important;
}
.row-filters input[type="text"]:-ms-input-placeholder,
.operation-filters input[type="text"]:-ms-input-placeholder { /* IE 10+ */
  color:#787878 !important;
}
.row-filters input[type="text"]:-moz-placeholder,
.operation-filters input[type="text"]:-moz-placeholder { /* Firefox 18- */
  color:#787878 !important;
}
.row-filters input[type="text"]::placeholder,
.operation-filters input[type="text"]::placeholder {
    color:#787878 !important;
}

.autocomplete-field {
    margin:0px 0 20px;
    position:relative;
}
.autocomplete-field.loading:after {
    content: "\F110";
    font: normal normal normal 14px/1 FontAwesome;
    position:absolute;
    z-index:4;
    right:15px;
    top:40px;
    font-size: 20px;
    color:#38B66D;
    animation:Loading 0.7s cubic-bezier(0.62, 0.12, 0.32, 0.98) 0s infinite;
}
.autocomplete-field input {
    border: none;
    height: 60px;
    font-size: 16px;
    font-weight: 600;
    padding: 15px;
    position:relative;
    z-index:3;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
}
.autocomplete-field .submit-autocomplete-field {
    display:block;
    position:absolute;
    right:0px;
    top:0;
    height:100%;
    width:60px;
    background-color:#39b66d;
    color:#ffffff;
    text-align:center;
    font-size:20px;
    border-radius:0 3px 3px 0;
    cursor:pointer;
    z-index:3;
}
.autocomplete-field .submit-autocomplete-field:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    opacity: 0.2;
    transition: all 0.35s cubic-bezier(0.74,0.2,0.51,1.04);
}
.autocomplete-field .submit-autocomplete-field:hover:after, 
.autocomplete-field .submit-autocomplete-field:hover:after {
    right: -100%;
}

.autocomplete-field .submit-autocomplete-field i {
    position:absolute;
    top:50%;
    left:50%;
    transform:translateX(-50%) translateY(-50%);
}

.row-filters .form-group-filters {
    display:inline-block;
    vertical-align:top;
    margin-right:5px;
    margin-bottom:10px;
    max-width: 300px;
}

.row-filters:not(.noajax) .group-filter-submit {
    display:none;
}



.cta-filters {
    position: relative;
    text-align: center;
    padding: 12px 25px;
    border-radius: 3px 3px 0 0;
    font-size: 12px;
    font-weight: 700;
    background-color:#38B66D;
    color:#ffffff;
    text-transform: uppercase;
    padding-right:40px;
    transition: all 0.25s ease;
    display:none;
}

.cta-filters:hover,
.cta-filters:focus {
    color:#ffffff;
}

.filtered .cta-filters:before {
    content:"";
    display:inline-block;
    vertical-align:top;
    width:6px;
    height:6px;
    margin-right: 10px;
    margin-top: 5px;
    border-radius:100%;
    background-color:#ffffff;
}

.cta-close-filters {
    display:none;
}

.cta-filters:after {
    content: '';
    border-top: 8px solid #ffffff;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    float: right;
    position: absolute;
    right: 20px;
    top: 16px;
    transition: all 0.25s ease, visibility 0s linear 0.3s;
}

.filters-open .cta-filters:after {
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    top: 11px;
}

@media(min-width:992px) {
    .group-filters-list {
        display:flex !important;
        flex-wrap: wrap;
    }
    .group-filters-list .form-group,
    .group-filters-list .form-group-custom {
        margin: 0 10px 10px 0;
    }
}


.row-filters .select2 {
    max-width:100%;
    min-width: 150px;
}

.select2-container {
    min-width: 150px;
}

.form-control,
.select2-container--default .select2-selection {
    border-radius:3px;
    font-size:13px;
    font-weight:600;
    height:40px;
    /*color:#34495E;*/
    color:#787878;
    padding: 9px 12px;
    border-color: #d2d6de;
}
.select2-container--default .select2-selection {
    height:auto;
    min-height:40px;
}
textarea.form-control {
    min-height:150px;
}
.select2-container--default .select2-selection .select2-selection__placeholder {
    color:#787878;
}
.select2-container--default .select2-selection .select2-selection__arrow {
    top: 7px;
    right: 2px;
}
.select2-container--default .select2-selection .select2-selection__arrow b {
    border-color:#38B66D transparent transparent transparent;
    border-width: 8px 5px 0 5px;
    margin-left:-10px;
}
.select2-search--dropdown {
    padding:15px 10px;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    height:40px;
}

.select2-container--default .select2-search--inline .select2-search__field {
    margin:0;
    max-width: 100%;
    width: 100% !important;
    cursor: inherit;
}

.select2-container--default .select2-selection .select2-selection__rendered { 
    padding:0;
}




/** Autocomplete choix multiples **/
.select2-container--default .select2-selection.select2-selection--multiple {
    border: 0;
    padding: 0px 0;
    background-color:transparent;
}
.select2-container--default .select2-selection.select2-selection--multiple > ul {
    padding-top:40px;
    position:relative;
}
.select2-container--default .select2-selection.select2-selection--multiple > ul li.select2-search {
    margin: 0;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 600;
    height: 40px;
    color: #34495E;
    padding: 9px 12px;
    border: 1px solid #d2d6de;
    background-color: #ffffff;
}


.select2-container--default .select2-selection .select2-selection__choice {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    position: relative;
    font-weight: 600;
    background-color: #ffffff;
    padding: 5px 12px 5px 10px;
    border-radius: 17px;
    font-size: 13px;
    color: #414141;
    margin: 10px 15px 0 0;
    border:none;
}
.select2-container--default.select2-container--open .select2-selection .select2-selection__choice {
    position:absolute;
}

.select-custom .select2-container--default .select2-selection .select2-selection__choice {
    display: none;
}

.select2-container--default .select2-selection .select2-selection__choice .select2-selection__choice__remove {
    width: 18px;
    height: 18px;
    text-align: center;
    font-size: 12px;
    line-height:18px;
    padding-left: 1px;
    font-size: 18px;
    color: #ffffff;
    background-color: #38B66D;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    margin-top: -2px;
    cursor: pointer;
    position: relative;
}

/*.select2-results__options[aria-multiselectable="true"]*/
.select2-dropdown {
    border:1px solid #d2d6de;
    border-radius:2px;
    margin-top: -5px;
}
.select2-results__option[aria-selected] {
    background-color:#ffffff;
}

.select2-hidden-accessible[disabled] + .select2 .select2-selection__choice__remove {
    display:none;
}

.select2 {
    max-width:100%;
}

.select2.hasValues:before,
.form-group.hasValues:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: -2px;
    right: -2px;
    border-radius: 100%;
    width: 8px;
    height: 8px;
    background-color: #38B66D;
}

/*** SELECT OPTION MULTIPLE : STYLE CHECKBOX ***/
.select2-container--default .select2-results__option {
    padding-left: 35px;
    position: relative;
    font-size: 13px;
    font-weight: 600;
}

.select2-container--default .select2-results__option:before {
    content: "\f14a";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
    line-height: 12px;
    position:absolute;
    font-size: 18px;
    line-height: 17px;
    position: absolute;
    left: 10px;
    top: 9px;
    width: 15px;
    height: 15px;
    border-radius:3px;
    background-color: #fff;
    box-shadow: 0 0 0 1px #d4d4d4 inset;
    color:transparent;
}

.select2-container--default .select2-results__options.empty .select2-results__option {
    padding-left: 12px;
    background-color: #efefef;
    color: #414141;
    font-style: italic;
    opacity: 0.4;

    &:before {
        content: none;
    }
}

.select2-container--default .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted) {
    background-color: #fff;
    color: inherit;
}

.select2-container--default .select2-results__option[aria-selected=true]:before {
    color:#38B66D;
    box-shadow: none;
}

.row-filters .filter-action {
    display:inline-flex;
    float:left;
}

.row-filters {
    @media(min-width:992px) {
        .filter-reset {
            text-indent: -9999px;
            border: none;
            background: none;
            width: 25px;
            height: 25px;
            font-size:20px;
            padding: 0;
            margin:7px 10px;
            position: relative;
            color:#34495E;
            outline:0;
            float:none;
            transition:all 0.25s ease;
            flex-direction: row-reverse;
            display:none;

            i {
                display: block;
                text-indent: 0;
                position: absolute;
                left: 50%;
                top: 50%;
                //transform: translate(-50%, -50%);
                transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:hover {
                transform:rotate(90deg);
            }
        }
    }

    &.filtered {
        .filter-reset {
            display:inline-flex;
        }
    }
}

/** Filtres liste opérations **/
.operation-filters .row-filters .form-group {
    /*float:left;*/
    display:inline-block;
    vertical-align:top;
}

.row-filters .group-period {
    display:inline-block;
    vertical-align:top;
    /*float:left;*/
}
.period-date .period-flex {
    display:flex;
    align-items:center;
    gap: 10px;
}
.row-filters .group-period.period-date {
    border-radius:0 3px 3px 0;
    height: 40px;
    border: 1px solid #d2d6de;
    background-color:#ffffff;
    /* margin-left: -5px; */
    position:relative;
    width: 100%;
}
.row-filters .group-period.period-date.disabled:after {
    content:'';
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    /*background-color:rgba(255,255,255,0.5);*/
    background-color: rgba(243, 243, 243, 0.64);
}

.row-filters .group-period.period-date input {
    display: inline-block;
    width: auto;
    border: none;
    height: 100%;
    width: 135px;
    padding: 9px 0 9px 12px;
}
.row-filters .group-period.period-date input[disabled] {
    background-color:transparent;
}

.row-filters .group-period.period-date input + i {
    vertical-align:middle;
}

.group-checkbox {
    padding:0 15px;
}

.group-checkbox input {
    display:none;
}
.group-checkbox label {
    position:relative;
    font-weight:400;
    cursor:pointer;
    padding: 8px 18px 8px 25px;
}
.group-checkbox label:before {
    content: "\f14a";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 18px;
    line-height: 17px;
    position:absolute;
    left:0;
    top:9px;
    width:16px;
    height:16px;
    border-radius:3px;
    box-shadow: 0 0 0 1px #d4d4d4 inset;
    color:transparent;
}
.group-checkbox input:checked + label {
    font-weight:600;
}

.group-checkbox input:checked + label:before {
    color:#38B66D;
    box-shadow: none;
}

.reset-date-field {
    text-indent:-9999px;
    margin-left:10px;
    position:relative;
    width:32px;
    height:25px;
    background-color: #38B66D;
    border:none;
    border-radius:3px;
    padding:0;
    display:none;
}
.reset-date-field:before,
.reset-date-field:after {
    content:'';
    position:absolute;
    left: 12px;
    top: 7px;
    width: 2px;
    height: 12px;
    background-color:#fff;
}
.reset-date-field:before {
    transform:rotate(45deg);
}
.reset-date-field:after {
    transform:rotate(-45deg);
}

.group-flex {
    display:flex;
    align-items:center;

    > div {
        margin-right:30px;
    }

    .furniture-pagination {
        margin: 0;
        flex: 0 0 auto;
    }

    &.group-flex-header {
        @media(max-width: 767px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .furnitures-list {
                margin: 0 auto 15px;
            }
        }
    }
}

.select-hours {
    position:relative;
}
/*.select-hours select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance:none;
}
.select-hours select::-ms-expand {
    display: none; 
}*/

.form-custom .ct-dropdown,
.form-custom .select-hours select {
    min-width:75px;
    vertical-align: middle;
    margin-right:10px;
}
.form-custom .select-hours select {
    /*width:75px;*/
    padding: 6px 12px;
    font-size: 14px;
    color: #555;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-custom .ct-dropdown + .ct-dropdown,
.form-custom .select-hours select + select {
    margin-left:10px;
    margin-right:0;
}

.search-field {
    min-width:225px;
    position:relative;
}
.search-field:after {
    content: "\f002";
    font: normal normal normal 14px/1 FontAwesome;
    position:absolute;
    right:0;
    top:50%;
    transform:translateY(-50%);
    font-size:20px;
    color:#38B66D;
}
.search-field input {
    border:none;
    font-size:14px;
    position: relative;
    z-index: 1;
    padding-right: 25px;
    background-color: transparent;
}


.row-filters form {
    margin:0;
}

.row-filters [type=submit] {
    /*text-indent:-9999px;
    border:none;
    background:none;
    position:relative;
    width:30px;
    height:30px;
    outline:0;
    transform: rotate(90deg);*/
}
.row-filters [type=submit] i {
    /*text-indent:0px;
    position:absolute;
    left:50%;
    top:50%;
    transform:translateX(-50%) translateY(-50%);
    font-size: 20px;*/
}

.edit-quotas,
.edit-furnitures {
    margin:0 0 20px;
}

/** Content **/
.row-content.loading,
.content-wrapper .content.loading,
.furniture-container-wrapper.loading,
.furnitures-list-wrapper.loading,
.command-step.loading {
    position:relative;
}
.row-content.loading:after,
.content-wrapper .content.loading:after,
.furniture-container-wrapper.loading:after,
.furnitures-list-wrapper.loading:after,
.command-step.loading:after {
    content: "\F110";
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    left: 50%;
    top:80px;
    z-index:3;
    color: #38B66D;
    font-size: 20px;
    animation: Loading 0.7s cubic-bezier(0.62, 0.12, 0.32, 0.98) 0s infinite;
}

.row-content.loading:before,
.content-wrapper .content.loading:before,
.furniture-container-wrapper.loading:before,
.furnitures-list-wrapper.loading:before,
.command-step.loading:before {
    content:'';
    display:block;
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color:#ffffff;
    opacity:0.5;
    z-index:2;
}

.furniture-container-wrapper.loading:after {
    font-size: 30px;
    margin-left: -15px;
}

.content-wrapper .content {
    padding:20px 0;
    border-top: solid 2px #d4d4d4;
}


/*** HOMEPAGE **/
.homepage h1 {
    margin-top:25px;
}
.bloc-hp {
    background-color:#ffffff;
    margin:20px 0;
    padding:20px 20px;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
}
.bloc-hp .title-5 {
    margin-left:-20px;
}
.bloc-hp .title-4 {
    margin-top:20px;
}
.bloc-hp a {
    color: inherit;
    text-decoration: underline;
}
.bloc-hp .bloc-text {
    font-size:14px;
}
.bloc-hp-agency {
    margin-top:10px;
}


/** Switchable vue **/
.switchable-view {
    float:right;
    border-radius:3px;
    border:1px solid #eaeaea;
    background-color:#f3f3f3;
}

.switch-page-table .switch-view-region {
    margin-bottom:15px;
}

.switchable-view a {
    width:32px;
    height:32px;
    padding:9px 8px;
    display:block;
    float:left;
    text-align:center;
    transition:all 0.15s ease;
}
.switchable-view a:first-child {
    border-right:1px solid #eaeaea;
}
.switchable-view a:hover,
.switchable-view a.active {
    background-color:#ffffff;
}
.switchable-view a.active {
    cursor:default;
}

.switchable-view a.large-view {
    padding:10px 8px;
}

.switchable-view a.table-view {
    padding:8px 8px;
}
.switchable-view a.table-view i {
    padding-top: 3px;
}

.switchable-view a i {
    color:#c1c1c1;
    font-size:18px;
    transition:all 0.15s ease;
}

.switchable-view a span {
    display:block;
    background-color:#c1c1c1;
    margin-bottom:2px;
    transition:all 0.15s ease;
}
.switchable-view a.small-view span,
.switchable-view a.tree-view span { 
    height:2px;
}
.switchable-view a.large-view span {
    height:5px;
}

.switchable-view a:hover span,
.switchable-view a.active span {
    background-color:#38B66D;
}

.switchable-view a:hover i,
.switchable-view a.active i {
    color:#38B66D;
}

/**** TABLES ****/
.row-table {
    margin-top:20px;
}
table.dataTable,
.table-custom {
    background-color:#ffffff;
    border-radius:3px;
    width:100%;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
}
.table-custom > thead {
    background-color:#34495E;
    color:#ffffff;
}
.table-custom > thead > tr > th {
    font-size: 13px;
    text-transform: uppercase;
    padding: 15px 15px;
    border-left:1px solid #e0e0e0;
    border-bottom-color:#ececec;
}
.table-custom > thead > tr > th:first-child {
    border-left:0;
}

.table-custom > tbody > tr {
    transition:all 0.2s ease;
}
@media(min-width:992px) {
    .table-custom > tbody > tr:first-child td {
        border-top:0;
    }
}

.table-custom > tbody > tr:hover,
.table-custom > tbody > tr.focus {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.11);
}

.table-custom > tbody > tr > td {
    font-size:13px;
    font-weight:600;
    padding:15px 15px;
    vertical-align:middle;
    border-top:1px solid #e0e0e0;
    border-left:1px solid #e0e0e0;
    color:#787878;

    .shop-icon-check {
        margin-right: 10px;
    }
}

@media(min-width:992px) {
    .table-custom > tbody > tr:hover > td,
    .table-custom > tbody > tr.focus > td {
        color:#414141;
    }
}

.table-custom > tbody > tr > td:first-child {
    position:relative;
    border-left:0;
    /*transition:all 0.2s ease;*/
}
.table-custom > tbody > tr > td:first-child:after {
    content:'';
    position:absolute;
    left:0;
    top:0;
    height:100%;
    width:0px;
    background-color:#39b66d;
    transition:all 0.2s ease;
}
.table-custom > tbody > tr:hover > td:first-child,
.table-custom > tbody > tr.focus > td:first-child {
    /*border-left:4px solid #39b66d;*/
}
.table-custom > tbody > tr:hover > td:first-child:after,
.table-custom > tbody > tr.focus > td:first-child:after {
    width:4px;
}

.table-custom > tbody > tr:hover .box-actions .actions-btn,
.table-custom > tbody > tr.focus .box-actions .actions-btn {
    background-color:#39b66d;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
    font: normal normal normal 14px/1 FontAwesome;
    bottom:auto;
    top:50%;
    margin-top:-7px;
}

table.dataTable thead .sorting:after {
    content:"\f0ec";
    transform:rotate(90deg);
}
table.dataTable thead .sorting_asc:after {
    content: "\f160";
}
table.dataTable thead .sorting_desc:after {
    content: "\f161";
}



/** Table resize **/
.row-table-resize {
    /*overflow-y:auto;*/
}
.table-resize {
    table-layout: fixed;
    /*width:99%;
    min-width:800px;*/
}
.table-resize > thead > tr > th {
    position:relative;
    /*width:100px;*/
    height:60px;
    transition:all 0.4s ease;
}
/*.table-resize > thead > tr > th.th-status {
    width:150px;
}*/
/*.table-resize > thead > tr > th.th-nomOpe {
    width:150px;
}*/
.table-resize > thead > tr > th.th-idCommand {
    width:100px;
}
.table-resize > thead > tr > th.th-secteur {
    width:90px;
}

/*.table-resize > thead > tr > th.th-dateCommand {
    width:90px;
}
.table-resize > thead > tr > th.th-dateOpe {
    width:90px;
}*/

.table-resize > thead > tr > th.open {
    width:250px !important;
}
.table-resize > thead > tr > th.cell-comment.open {
    width:450px !important;
}

.table-resize > thead > tr > th .bigger {
    width:20px;
    height:20px;
    display:block;
    position: absolute;
    bottom: 2px;
    right: -1px;
    cursor:pointer;
}
.table-resize > thead > tr > th .bigger:after {
    content: '';
    position: absolute;
    bottom: 2px;
    right: -1px;
    border-top: 7px solid #38B66D;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    transform: rotate(-45deg);
    width:0px;
    transition:all 0.3s ease;
}
.table-resize > thead > tr > th.open .bigger:after {
    border-left-width: 0px;
    border-right-width:0px;
    border-top-width: 4px;
    width:10px;
    right: 2px;
    transform:rotate(0);
}

.table-resize > tbody > tr > td {
    overflow:hidden;
}
.table-resize > tbody > tr > td.voverflow {
    overflow:visible;
}
.table-resize > tbody > tr:nth-child(even) {
    background-color:#fafafa;
}
.table-resize > tbody > tr > td > div {
    min-width:200px;
}
.table-resize > tbody > tr > td > div.box-actions {
    min-width:0;
}
.table-resize > tbody > tr > td.cell-comment > div {
    min-width:420px;
}

.date-action li {
    margin:7px 0;
}


/** Table tri animation **/
.ct-table-sorting {
    overflow-x: auto;
}

.table-sorting {
    width:100%;
}
.table-sorting > thead > tr,
.table-white > thead > tr {
    border-bottom:3px solid #d8d8d8;
}
.table-sorting > thead > tr > th,
.table-white > thead > tr > th {
    font-size: 12px;
    font-weight:600;
    /*padding: 8px 15px;*/
    padding:12px 15px;
    vertical-align: middle;
    background-color:#ffffff;
    color: #9da0a5;
    border:none;
    text-transform:none;
}
.table-white > thead > tr > th {
    border-left: 1px solid #e0e0e0;
}
.table-sorting > tbody > tr > td {
    border:none;
}

.animation-sorting th:first-child,
.animation-row .table-custom > tbody > tr > td:first-child {
    width:30%;
    /*min-width:130px;*/
    min-width:100px;
}
.animation-list.nb-col5 .animation-sorting th:first-child,
.animation-list.nb-col5 .animation-row .table-custom > tbody > tr > td:first-child {
    width: 39%;
    min-width:100px;
}
.animation-sorting th:nth-child(2),
.animation-row .table-custom > tbody > tr > td:nth-child(2) {
    width:10%;
    /*min-width:130px;*/
    min-width:100px;
}
.animation-sorting th:nth-child(3),
.animation-row .table-custom > tbody > tr > td:nth-child(3) {
    width: 7%;
    min-width:100px;
}
.animation-sorting th:nth-child(4),
.animation-row .table-custom > tbody > tr > td:nth-child(4) {
    width: 7%;
    min-width:100px;
}
.animation-sorting th:nth-child(5),
.animation-row .table-custom > tbody > tr > td:nth-child(5) {
    width: 9%;
    min-width:100px;
}
.animation-sorting th:nth-child(6),
.animation-row .table-custom > tbody > tr > td:nth-child(6) {
    width: 5%;
    min-width:100px;
}

.animation-row .table-custom {
    border-radius:0;
    box-shadow:none;
    border-top:1px solid #ececec;
}
.animation-item:first-child .animation-row .table-custom {
    border-top:0;
}
.animation-item:nth-child(even) .animation-row .table-custom {
    background-color:#fafafa;
}

.status-item:before {
    content:'';
    display:inline-block;
    vertical-align:top;
    margin-right:8px;
    margin-top:3px;
    width:12px;
    height:12px;
    border-radius:100%;
    background-color:#c1c1c1;
}
.status-item.status-active:before {
    background-color:#7ed321;
}


.box-actions {
    position:relative;
    display:table;
    margin:auto;
}
.box-actions .actions-btn {
    width:30px;
    height:30px;
    border-radius:100%;
    line-height:30px;
    text-align:center;
    color:#ffffff;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.22);
    position:relative;
    z-index: 1;
    cursor:pointer;
    transition:all 0.2s ease;
}
.box-actions .actions-btn:not([class*=bgcolor]) {
    background-color:#c1c1c1;
}

.is-ordeable .box-actions .actions-btn:not([class*=bgcolor]) {
    background-color:#929292;
}

.box-actions .actions-btn:hover {
    background-color:#39b66d;
}

.box-actions .actions-btn:after {
    content: "\F013";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size:18px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
.box-actions.open .actions-btn:after {
    content: "\F067";
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    padding-top: 2px;
}

.box-actions > ul {
    display:none;
    position:absolute;
    top: 100%;
    right: 100%;
    margin-top: -15px;
    margin-right: -15px;
    background-color:#ffffff;
    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
    border-radius:3px;
}
.box-actions > ul li a,
.box-actions > ul li button {
    display:block;
    min-width:140px;
    padding:8px 15px;
    color:#656d7c;
    font-size:13px;
    font-weight:600;
    border-top:1px solid #f3f3f3;
    transition:all 0.15s ease;
}
.box-actions > ul li button {
    border: none;
    border-top: 1px solid #f3f3f3;
    background: transparent;
    text-align: left;
}
.box-actions > ul li:first-child a,
.box-actions > ul li:first-child button {
    border-top:0;
}
.box-actions > ul li a:hover,
.box-actions > ul li button:hover {
    color:#34495E;
    background-color:#f3f3f3;
}

.box-actions.open {
    z-index:4;
}

.box-actions.open > ul {
    display:block;
}


.delete-btn {
    width:30px;
    height:30px;
    border-radius:100%;
    border:none;
    line-height:30px;
    text-align:center;
    background-color:#c1c1c1;
    color:#ffffff;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.22);
    position:relative;
    z-index: 1;
    cursor:pointer;
    text-indent:-9999px;
    transition:all 0.2s ease;
}
.delete-btn:hover {
    background-color:#39b66d;
}

.delete-btn:after {
    content: "\f1f8";
    display: inline-block;
    text-indent:0;
    font: normal normal normal 14px/1 FontAwesome;
    line-height: 32px;
    font-size:17px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}


/** Operation **/
.type-ope {
    font-size: 13px;
    font-weight: 700;
    margin-bottom: 3px;
    min-height: 20px;
    text-transform: uppercase;
    margin-bottom: 25px;
    margin-left: 32px;
    margin-top: -8px;
    color:#38B66D;
    /*border-bottom: 1px solid #dadada;*/
    /*padding-bottom: 15px;*/
}

.form-operation .title-3 {
    border-bottom:1px solid #dadada;
    padding-bottom:10px;
}

.operation-dates {
    margin-top:20px;
    margin-bottom:20px;
    padding-top:15px;
    /*border-top:1px solid #dadada;*/
}

.dates-tabs {
    display:table;
    margin:15px 0 45px;
}

.dates-tabs li {
    display:inline-block;
    margin:0 15px 0 0;
}

.dates-tabs li.error:before {
    content: "\f00d";
    color: red;
    display: inline-block;
    border-radius: 100%;
    font: normal normal normal 14px/1 FontAwesome;
}

.dates-tabs li a {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;
    padding: 0 0px 10px;
    color: #ababab;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
    position:relative;
    text-transform: uppercase;
    transition: all 0.25s ease;
}

.dates-tabs li a:before {
    content:'';
    position:absolute;
    bottom:0;
    left:-10px;
    right:-10px;
    height:4px;
    background-color:#ababab;
    transition: all 0.25s ease;
}
.dates-tabs li a:after {
    content: '*';
    color: red;
    margin-left: 3px;
}
.dates-tabs li a[data-type="dates-publish"]:after {
    display:none;
}

.dates-tabs li a:hover,
.dates-tabs li.active a {
    color:#38B66D;
}

.dates-tabs li a:hover:before,
.dates-tabs li.active a:before {
    background-color:#38B66D;
}

.dates-item {
    display:none;
}

.dates-inner {
    display: flex;
    margin: auto;
    /*justify-content: center;*/
}

.dates-group {
    display:inline-block;
    vertical-align:top;
    padding: 0 0 0 30px;
    border-left:1px solid #dadada;
}
.dates-group:first-child {
    padding:0;
    border:none;
}



.ct-dropdown-bis {
    position: relative;
    border-radius: 3px;
    font-size: 13px;
    height: 40px;
    border:1px solid #d2d6de;
    z-index: 1;
    display: inline-block;
}
.ct-dropdown-bis.new_operation {
    border:none;
    float:right;
}
.ct-dropdown-bis.new_operation.open {
    z-index:5;
}
.ct-dropdown-bis .dropdown-label-bis {
    display: block;
    height:100%;
    cursor: pointer;
    position:relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    background-color: #38B66D;
    color: #ffffff;
    font-size: 11px;
    font-weight: 700;
    padding: 12px 45px 12px 25px;
    border-radius: 3px;
    text-transform:uppercase;
}

.ct-dropdown-bis .dropdown-label-bis:after {
    content: '';
    border-top: 8px solid #38B66D;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    float: right;
    position:absolute;
    right:10px;
    top:15px;
    transition: all 0.25s ease, visibility 0s linear 0.3s;
}
.ct-dropdown-bis.open .dropdown-label-bis:after {
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    transform:rotate(180deg);
    top:14px;
}

.ct-dropdown-bis .dropdown-label-bis:after {
    border-top-color:#ffffff;
    right: 25px;
    top: 16px;
}

.ct-dropdown-bis .dropdown-list-bis {
    display: none;
    background-color: #ffffff;
    position: absolute;
    top: 100%;
    padding: 0;
    list-style-type: none;
    left: -1px;
    right: -1px;
    margin: 0;
    border:1px solid #d2d6de;
    border-radius:0 0 3px 3px;
    overflow:auto;
    max-height:300px;
}
.ct-dropdown-bis .dropdown-list-bis li {
    margin-top:1px;
    display: block;
    padding: 8px 18px;
    cursor:pointer;
    transition: all 0.08s ease-in-out;
}


.ct-dropdown-bis .dropdown-list-bis {
    margin-top: -2px;
}
.ct-dropdown-bis .dropdown-list-bis li {
    padding:0;
    margin:0;
}
.ct-dropdown-bis .dropdown-list-bis li:hover {
    background:transparent;
}
.ct-dropdown-bis .dropdown-list-bis li a {
    color: #656d7c;
    font-weight: 600;
    display:block;
    padding: 8px 18px;
    border-top: 1px solid #f3f3f3;
}
.ct-dropdown-bis .dropdown-list-bis li:first-child a {
    border-top:0;
 }
.ct-dropdown-bis .dropdown-list-bis li a:hover,
.ct-dropdown-bis .dropdown-list-bis li a:active {
    color:#34495E;
    background-color:#f3f3f3;
}

.new_operation .cta i {
    margin-left: 8px;
    vertical-align: top;
    margin-top: -5px;
    font-size: 17px;
}
/*.field-search {
    background-color: #ffffff;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
    border-radius:3px;
}*/
/*.field-search input[type="text"] {
    border:none;
    height:60px;
    font-size: 16px;
    font-weight: 600;
    padding:15px;
}*/

.operation-filters form {
    margin:0;
}
.title-cat {
    font-size:11px;
    font-weight:700;
    margin-bottom: 3px;
    min-height: 20px;
    text-transform:uppercase;
}
.title-cat i {
    display:inline-block;
    margin-right:5px;
    font-size:15px;
    color:#38B66D;
}

.animation-cat {
    font-size:12px;
    text-transform:uppercase;
    font-weight:700;
    margin-bottom:8px;
    color:#38B66D;
}
.animation-info {
    text-align:center;
}
.animation-info .title-3 {
    color:#414141;
}

.animation-info-command {
    padding:15px 20px;
    border:2px dashed #c1c1c1;
    border-radius:3px;
    text-align:center;
    max-width:280px;
    color: #34495E;
    font-weight: 600;
    font-size: 13px;
}
.animation-info-command .cta {
    margin:15px auto 0;
}

.animation-info-command + div {
    margin-bottom:30px;
}

.btn-see-details {
    display:none;
}

.command-period {
    margin-bottom:5px;
}
.days-until {
    text-transform:uppercase;
    font-size:11px;
    font-weight:700;
    margin-top:8px;
}
.until-count {
    display:inline-block;
    vertical-align:middle;
    margin-left:5px;
}
.until-count > span {
    display:inline-block;
    vertical-align:middle;
    padding:3px 6px;
    margin:-3px 1px 0;
    border-radius:3px;
    font-size:14px;
    line-height: 14px;
    font-weight:500;
    color:#ffffff;
    background-color:#34495E;
}

.ct-add-to-fav {
    position:relative;
    width:30px;
    height:25px;
}
.add-to-fav {
    text-indent:-9999px;
    position:relative;
    width:30px;
    height:25px;
}
.add-to-fav > span {
    display:none;
}
.add-to-fav i {
    display:block;
    text-indent:0;
    color:#38B66D;
    font-size:20px;
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-13px;
    margin-top:-10px;
}
.add-to-fav i.empty {

}
.add-to-fav i.full {
    transition:all 0.3s ease;
    transform:scale(0);
}
.add-to-fav.added i.full {
    transition:all 0.3s cubic-bezier(0.49, -0.12, 0.31, 1.88);
    transform:scale(1);
}

.popin-fav {
    position: absolute;
    top: 0;
    right: 100%;
    margin-right: 20px;
    padding: 4px 5px;
    border-radius: 3px;
    /*background-color: rgba(31, 181, 234,0.6);*/
    background-color:#38B66D;
    width: 120px;
    text-align: center;
    font-size: 11px;
    transition: all 0.1s cubic-bezier(0.34, 0.24, 0.66, 0.71);
    opacity: 0;
    visibility:hidden;
}

.add-to-fav.added ~ .popin-fav.added {
    animation:popinFav 2s ease-in-out 0s 1 both alternate;
}
.add-to-fav.removed ~ .popin-fav.removed {
    animation:popinFav 2s ease-in-out 0s 1 both alternate;
}

@-moz-keyframes popinFav {
    0% { visibility:visible; }
    1% { opacity:0; }
    10% { opacity:1; }
    90% { opacity:1; }
    99% { opacity:0; }
    100% { visibility:hidden; }
}
@-webkit-keyframes popinFav {
    0% { visibility:visible; }
    1% { opacity:0; }
    10% { opacity:1; }
    90% { opacity:1; }
    99% { opacity:0; }
    100% { visibility:hidden; }
}
@-o-keyframes popinFav {
    0% { visibility:visible; }
    1% { opacity:0; }
    25% { opacity:1; }
    75% { opacity:1; }
    99% { opacity:0; }
    100% { visibility:hidden; }
}
@-ms-keyframes popinFav {
    0% { visibility:visible; }
    1% { opacity:0; }
    25% { opacity:1; }
    75% { opacity:1; }
    99% { opacity:0; }
    100% { visibility:hidden; }
}
@keyframes popinFav {
    0% { visibility:visible; }
    1% { opacity:0; }
    10% { opacity:1; }
    90% { opacity:1; }
    99% { opacity:0; }
    100% { visibility:hidden; }
}


/** Tables **/
.animation-item {
    /*margin-bottom:50px;*/
    position:relative;
}
.animation-item:last-child {
    margin-bottom:0px;
}
.animation-item.hover {
    z-index:3;
}
.animation-head-wrap {
    background-color:#34495E;
    color:#ffffff;
    display:flex;
    justify-content:center;
    align-items:stretch;
    padding:0px 15px;
    position:relative;
}

.animation-head-item {
    padding:10px 25px;
    font-weight:500;
    /*border-left:1px solid #dadada;*/
    border-left:1px solid rgba(255,255,255,0.5);
    text-align:center;
    position:relative;
}
.animation-head-item:first-child {
    border-left:0;
}
.animation-head-item span {
    font-size:12px;
    font-weight:600;
}
.animation-head .title-cat {
    color:#ffffff;
}
.animation-head .title-cat i,
.animation-head .title-cat:before {
    display: inline-block;
    height:20px;
    font-size:20px;
    margin-top: -3px;
    vertical-align: top;
    margin-right: 5px;
    position: relative;
    color: #38B66D;
}
/*.animation-head .title-cat.availability:before {
    content: "\F274";
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 15px;
    color: #38B66D;
    vertical-align:middle;
}*/
/*.animation-head .title-cat.duration i {
    content:url(../img/icon-duree.svg); 
    top: -2px;
}
.animation-head .title-cat.quota:before {
    content:url(../img/icon-quotas.svg);
}
.animation-head .title-cat.price:before {
    content:url(../img/icon-price.svg);
    top: -2px;
}*/

.animation-head .title-cat.availability i {
    font-size:16px;
    margin-top:0;
}

.animation-head .ct-add-to-fav {
    position:absolute;
    right:15px;
    top:50%;
    transform:translateY(-50%);
}
.animation-head .add-to-fav {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}

.animation-wrap {
    position:relative;
    background-color:#ffffff;
}

.animation-content {
    position:relative;
    /*min-height:200px;*/
    background-color:#ffffff;
    padding:35px 20px 35px;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
    box-sizing:content-box;
}
.animation-content .animation-info-command {
    display: table;
    margin: 0 auto 30px;
    max-width:none;
}

.animation-content .animation-info-command .command-period {
    display: inline-block;
    margin-right:6px;
}
.animation-content .animation-info-command .command-period + span {
    display: inline-block;
}
.animation-content .animation-info-command .days-until {
    display: inline-block;
    margin-left:30px;
}
.animation-content .animation-info-command .cta {
    margin: 0 0 0 30px;
    display: inline-block;
    vertical-align: middle;
}

.animation-head .box-actions {
    position: absolute;
    right: 20px;
    top: 50%;
    margin-top: -15px;
}
.animation-head .box-actions .actions-btn {
    background-color:#38B66D;
}

.animation-head .close-animation {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    line-height: 30px;
    text-align: center;
    background-color: #39b66d;
    color: #ffffff;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.22);
    position: relative;
    z-index: 1;
    cursor: pointer;
    position: absolute;
    right: 45px;
    top: 50%;
    margin-top: -15px;
    transition: all 0.2s ease;
}
.animation-head .close-animation:after {
    content: '+';
    line-height: 30px;
    font-size: 30px;
    display: block;
    font-weight: 500;
    transform: rotate(45deg);
}

.animation-content .title-2 {
    font-size: 24px;
    font-weight: 600;
    color:#414141;
    text-align:center;
}
.animation-content .animation-cat {
    text-align:center;
}

.animation-more {
    position:absolute;
    bottom:-25px;
    left:50%;
    transform:translateX(-50%);
    min-width:220px;
    text-align:center;
}
/*.open .animation-more {
    bottom:-25px;
}*/
.animation-more a {
    display:block;
    color:#34495E;
    font-size:11.5px;
    font-weight:700;
    text-transform:uppercase;
    position:relative;
}
.animation-more a:after {
    content:'+';
    width:34px;
    height:34px;
    line-height:35px;
    display:table;
    margin:10px auto;
    border-radius:100%;
    font-size:28px;
    font-weight:400;
    color:#ffffff;
    text-align:center;
    background-color:#34495E;
    transition:all 0.25s ease;
}
.animation-more .more-open {
    display:block;
}
.animation-more .more-close {
    display:none;
}
.open .animation-more .more-open {
    display:none;
}
.open .animation-more .more-close {
    display:block;
}

.open .animation-more a:after {
    transform:rotate(225deg);
}

.bloc-text {
    font-size:15px;
    line-height:24px;
}
.bloc-text ul {
    padding:15px 10px;
}
.bloc-text ul li {
    position:relative;
    margin:0 0 3px;
    padding-left:30px;
}
.bloc-text ul li:before {
    content:'•';
    position:absolute;
    top:0px;
    left:0;
    font-size:14px;
}

.bloc-text ol {
    margin:15px 0;
    counter-reset: list;
}
.bloc-text ol li {
    position: relative;
    padding-left:20px;
    margin:6px 0;
    list-style-type: none;
    counter-increment: list;
}
.bloc-text ol li:before {
    content: counter(list);
    font-weight:700;
    color:#38B66D;
    position:absolute;
    left:0;
    top:-1px;
}

.bloc-text a {
    color:inherit;
    text-decoration:underline;
}

@media(min-width:768px) {
    .bloc-details.flex {
        display:flex;
        flex-wrap: wrap;
    }
}

.animation-focus {
    border-radius:3px;
    padding:25px 30px;
    margin-top:10px;
    background-color:#f7f7f7;
}
.animation-focus .title-4 {
    font-size: 16px;
    font-weight: 600;
    color:#414141;
}
.bloc-details .title-4 {
    font-size: 18px;
    font-weight: 600;
    color:#414141;
}
.bloc-details .title-4:before {
    margin-top:4px;
}

.animation-focus > div,
.bloc-details > div {
    margin:20px 0 0;
}

.bloc-details.flex > div {
    flex: 1 0 50%;
    margin:0 0 20px;
    padding-right: 50px;

    &.contact-shop {
        .info {
            display: table;
            margin: 12px 0 0;
            position: relative;
            padding-left: 30px;
            color: inherit;
            text-decoration: none;
            
            .icon {
                position: absolute;
                left: 0;
                top: 2px;
                font-size: 18px;
                width: 18px;
                text-align: center;
            }
        }
    }
}
.animation-focus > div:first-child,
.bloc-details:not(.flex) > div:first-child {
    margin-top:0;
}
.animation-focus ul li,
.bloc-details ul li {
    margin:5px 0;
}

.bloc-details .bloc-text {
    padding-left:20px;
    font-size:14px;
}

.bloc-details .bloc-text ul {
    padding:0;
}
.bloc-details .bloc-text ul li {
    padding:0px 0 0px 15px;
}

.bloc-details .table-custom {
    margin-bottom:35px;
}

.bloc-detail-date:before {
    content:'';
    width: 4px;
    height: 4px;
    border-radius:100%;
    background-color:#414141;
    display:inline-block;
    vertical-align:top;
    margin-top:10px;
    margin-right:8px;
}

.dl-link {
    color:#414141;
    position:relative;
    display:table;
    padding-bottom:5px;
}
.dl-link:hover,
.dl-link:focus {
    color:#414141;
}
.dl-link:before {
    content:'';
    position:absolute;
    left:0;
    right:0;
    bottom:0;
    height:3px;
    background-color:#e3e3e3;
}

.animation-focus + .cta {
    /*float:right;*/
    margin:20px auto;
}

.animation-teaser {
    padding:35px 20px 35px;
    background-color:#ffffff;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
}
.detail-opened .animation-teaser {
    position: absolute;
    left: 0;
    width: 100%;
}

.bloc-full {
    display:none;
    opacity:0 !important;
    transition:opacity 0.3s ease 0s;
}
.detail-opened .bloc-full {
    transition:opacity 0.3s ease 1s;
    opacity:1 !important;
}

.animation-teaser .col-md-6 {
    /*width:46%;*/
}
.animation-teaser .col-md-3 {
    /*width:29%;*/
}


.animation-list.loading {
    position:relative;
}
.animation-list.loading:after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:1;
    background-color:rgba(255,255,255,0.6);
}

.animation-list .ajax-loader {
    display:none;
}

.animation-list.loading .ajax-loader {
    position: absolute;
    display:block;
    top:0%;
    left:50%;
    margin-left:-10px;
    margin-top:100px;
    z-index:20;
    animation:Loading 0.7s cubic-bezier(0.62, 0.12, 0.32, 0.98) 0s infinite;
}

.animation-list.small-list {
    margin-top:-15px;
}


/*** VIEW MODE ***/
/*.small-list .animation-head,
.small-list .animation-teaser,
.small-list .animation-more,
.small-list .animation-item.open .animation-head,
.small-list .animation-content {
    visibility: hidden;
    opacity: 0;
    height:0;
    overflow:hidden;
    padding:0;
    transition: visibility 0s, opacity 0s linear;
}
.small-list .animation-teaser {
    padding:0;
}
.large-list .animation-head,
.large-list .animation-teaser,
.large-list .animation-more {
    visibility:visible;
    opacity:1;
    transition: visibility 0s, opacity 0.15s linear 0.2s;
}

.large-list .animation-row,
.large-list .animation-sorting {
    visibility: hidden;
    height:0;
    opacity: 0;
    transition: visibility 0s, opacity 0s linear;
}
.small-list .animation-row,
.small-list .animation-sorting {
    visibility:visible;
    opacity:1;
    transition: visibility 0s, opacity 0.15s linear;
}

*/

.large-list .animation-head .close-animation {
    display:none;
}
.large-list .animation-content .animation-info-command {
    display:none;
}


/* styles =/= selon le mode **/
.small-list .animation-content {
    border-left:4px solid #39b66d;
}
.large-list .animation-head-wrap {
    border-radius:3px 3px 0 0;
}
.large-list .animation-content {
    border-radius:0 0 3px 3px;
}
.large-list .animation-item {
    margin-bottom:50px;
}
.large-list .animation-item:first-child {
    margin-top:50px;
}


/* ouverture detail animation */
.animation-content {
    /*visibility: hidden;
    height:0;
    opacity: 0;
    transition: visibility 0s, opacity 0s linear;*/
    display:none;
    opacity: 0 !important;
    transition: opacity 0.3s ease 0s;
}

.animation-item.open .animation-head {
    visibility:visible;
    overflow:visible;
    opacity:1;
    height:auto;
    transition: visibility 0s, opacity 0.1s linear;
}
.animation-item.open .animation-content {
    /*visibility:visible;
    opacity:1;
    height:auto;
    transition: visibility 0s, opacity 0.3s linear;*/
    transition: opacity 0.3s ease 0.4s;
    opacity: 1 !important;
}
.animation-item.open .animation-teaser {
    /*visibility:hidden;
    opacity:0;
    height:0;
    padding:0;
    overflow:hidden;
    transition: visibility 0s, opacity 0s linear;*/
    position: absolute;
    left: 0;
    width: 100%;
    opacity:0;
    transition:opacity 0.3s ease 0s, visibility 0s linear 0.3s;
}




/** Slider **/
.product-slider .carousel-for {
    width:400px;
    height:400px;
    border:1px solid #dadada;
}
.product-slider .carousel-for .slick-list {
    height:100%;
}
.product-slider .carousel-for .slick-track {
    height:100%;
}
.product-slider .carousel-for .carousel-item {
    height:100%;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    outline:0;
    display:flex;
    align-items:center;
    padding: 0 30px;
}
.product-slider .carousel-for .carousel-item img {
    margin:auto;
}
.product-slider .carousel-nav {
    width:400px;
    margin-top:15px;
}
.product-slider .carousel-nav .slick-track {
    transform:none !important;
}
.product-slider .carousel-nav .slick-slide {
    height:auto;
}
.product-slider .carousel-nav .carousel-item {
    width:90px !important;
    height:90px;
    margin-left:13px;
    border:1px solid #dadada;
    background-size:cover;
    background-repeat:no-repeat;
    background-position:center;
    position:relative;
    outline:0;
    cursor:pointer;
}
.product-slider .carousel-nav .carousel-item:first-child {
    margin-left:0;
}
.product-slider .carousel-nav .carousel-item:after {
    content:'';
    position:absolute;
    left:0px;
    right:0px;
    bottom:0px;
    height:0;
    background-color:#38B66D;
    transition:all 0.3s ease;
}
.product-slider .carousel-nav .carousel-item.slick-current:after {
    height:5px;
}


.carousel-custom .slick-arrow {
    position:absolute;
    top:50%;
    transform:translateY(-50%);
    border-radius:100%;
    text-indent:-9999px;
    width:34px;
    height:34px;
    z-index: 1;
    border:none;
    background-color:#38B66D;
    outline:0;
}
.carousel-custom .slick-arrow.slick-prev {
    left:-17px;
}
.carousel-custom .slick-arrow.slick-next {
    right:-17px;
}
.carousel-custom .slick-arrow:before {
    content: "";
    font:normal normal normal 14px/1 FontAwesome;
    position:absolute;
    left:50%;
    top:50%;
    margin-top:-10px;
    transform: translateX(-50%);
    text-indent:0;
    color:#ffffff;
    font-size:20px;
}
.carousel-custom .slick-arrow.slick-prev:before {
    content: "\f177";
}
.carousel-custom .slick-arrow.slick-next:before {
    content: "\f178";
}


/** Bloc commande **/
.bloc-step {
    display:none;
}
.bloc-step.step-1 {
    display:block;
}

.step-tabs {
    display:flex;
    justify-content: center;
    margin:0 auto 30px;

    li {
        display:inline-flex;
        align-items: center;
        margin:0 15px;
        padding:0 10px 10px;
        color:#38B66D;
        font-size:13px;
        font-weight:700;
        cursor:pointer;
        text-transform:uppercase;
        border-bottom:4px solid #38B66D;
        opacity:0.4;
        transition:all 0.25s ease;

        span {
            flex: 0 0 auto;
            border-radius: 100%;
            width: 26px;
            height: 26px;
            line-height: 26px;
            font-size: 14px;
            font-weight: 700;
            margin-right: 8px;
            text-align: center;
            color: #ffffff;
            background-color:#38B66D;
            position:relative;
            text-indent:-9999px;
            transition:all 0.25s ease;

            &:after {
                content:"\f00c";
                font:normal normal normal 14px/1 FontAwesome;
                position:absolute;
                left:50%;
                top:50%;
                text-indent: 0;
                transform:translateX(-50%) translateY(-50%);
            }
        }

        &:hover {
            opacity:1;
        }

        &.clickable {
            cursor:pointer;
        }

        &.active {
            cursor:default;
            opacity:1;

            span {
                text-indent:0;

                &:after {
                    display:none;
                }
            }

            ~ li {
                cursor:default;
                color:#ababab;
                border-bottom-color:#eeeeee;
                opacity:1;

                span {
                    background-color: #ababab;
                    text-indent:0;

                    &:after {
                        display:none;
                    }
                }

                &.clickable {
                    cursor:pointer;

                    &:hover {
                        color:#38B66D;
                        border-bottom-color:#38B66D;

                        span {
                            background-color:#38B66D;
                        }
                    }
                }
            }
        }
    }

    @media(max-width: 767px) {
        flex-direction: column;
    }
}

.step-actions {
    display:table;
    margin:30px auto;
}
.step-actions a,
.step-actions button {
    display:inline-block;
    vertical-align:middle;
    margin:0 5px;
}

.step-actions .prev-step,
.step-actions .save-draft {
    display:none;
}


.command-container {
    background-color:#ffffff;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
    border-radius:0 0 3px 3px;
    padding:25px 50px;

    .checklist-item {
        border: 1px solid #d2d6de;
    }

    .operation-filters {
        .autocomplete-field input {
            &:focus {
                box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
            }
        }
    }

    @media(max-width: 767px) {
        padding: 25px 30px;
        margin-left: -15px;
        margin-right: -15px;
    }

    @media(max-width: 480px) {
        padding: 25px 15px;
    }

    @media(min-width: 768px) and (max-width: 991px) {
        padding:25px;
    }
}

.command-page .animation-teaser {
    position:relative;
    padding:20px;
}

.command-page .step-2 .ct-form-sbf td:last-child{
    min-width: 165px;
    text-align: center;
}

.command-page .step-2 .ct-form-sbf td:last-child p{
    display: inline-block;
}

@media all and (max-width: 767px){
    .command-page #cmd-step3 > .row{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.command-page .ct-form-sbf td:first-child img{
    object-fit: contain;
    max-width: 200px;
}

#order-detail .table-custom td:first-child img{
    object-fit: contain;
    max-width: 100px;
}

.command-page .step-3 .form-dates label{
    padding-left: 8px;
}

.command-page .step-container {
    @media(max-width: 767px) {
        .step-tabs {
            flex-direction: column;

            li {
                margin-bottom: 20px;

                .step-number{
                    margin-bottom: 8px;
                }
            }
        }
    }
}

.command-page.editing .step-3 .form-date{
    opacity: 0.5;
}

.ct-form-sbf.disabled{
    opacity: 0.5;
}

/** Autocomplete **/
.command-step .autocomplete-field input {
    box-shadow: 0 0 14px 3px rgba(0, 0, 0, 0.09);
}
.ui-autocomplete {
    z-index:2;
    background-color:#ffffff;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 0px 14px 3px;
}
.ui-autocomplete li {
    padding:15px 20px;
    cursor:pointer;
    transition:all 0.25s ease;
}
.ui-autocomplete li:hover {
    background-color:#f3f3f3;
}

.ct-command-filters {
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:2px solid #f1f1f1;

    .add-custom-shop {
        margin-top: 20px;
    }

    @media(max-width: 767px) {
        .ct-add-custom-shop {
            text-align: center;
        }
    }
}

.remove-custom-shop {
    margin-bottom: 15px;

    @media(min-width: 768px) {
        float: right;
    }

    @media(max-width: 767px) {
        margin-bottom: 30px;
    }
}

.ct-save-custom-shop {
    @media(max-width: 767px) {
        text-align: center;
    }
}

.command-filters {
    position:relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 5px;
}
.command-filters.loading:after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:1;
    background-color:rgba(255,255,255,0.6);
}
.command-filters .form-group {
    display:inline-block;
    vertical-align:top;
    margin:0 5px 0 0;
}

.command-filters .filter-reset {
    margin-top:30px;
}

.ajax-loader {
    color:#38B66D;
    font-size: 20px;
}

.command-filters .ajax-loader {
    display:none;
}
.command-filters.loading .ajax-loader {
    position: relative;
    z-index: 2;
    display:inline-block;
    margin:29px 0 0 5px;
    animation:Loading 0.7s cubic-bezier(0.62, 0.12, 0.32, 0.98) 0s infinite;
}

@-moz-keyframes Loading {
    0% { -moz-transform:rotate(0deg); }
    100% { -moz-transform:rotate(360deg); }
}
@-webkit-keyframes Loading {
    0% { -webkit-transform:rotate(0deg); }
    100% { -webkit-transform:rotate(360deg); }
}
@-o-keyframes Loading {
    0% { -o-transform:rotate(0deg); }
    100% { -o-transform:rotate(360deg); }
}
@-ms-keyframes Loading {
    0% { -ms-transform:rotate(0deg); }
    100% { -ms-transform:rotate(360deg); }
}
@keyframes Loading {
    0% { transform:rotate(0deg); }
    100% { transform:rotate(360deg); }
}

.command-shop {
    display:flex;
    justify-content:stretch;
    margin-bottom:30px;
    padding-bottom:30px;
    border-bottom:2px solid #f1f1f1;

    .form-group {
        width: 100%;

        textarea {
            min-height: 210px;
            color:#ababab;
            font-size:14px;
            font-weight:500;
            font-style:italic;
        }

        &:first-child {
            margin-right:20px;
        }
    }

    @media(max-width: 991px) {
        flex-direction: column;
    }
}


.form-group > label,
.form-group-custom > label {
    color:#787878;
    font-size:11px;
    margin-bottom:5px;
    font-weight:700;
    text-transform:uppercase;
}

.form-group-custom .check-list{
    max-height: 116px;
    overflow: auto;
}

.bloc-command {
    display:none;
}
.command-inner {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 2px solid #d4d4d4;
}
.command-inner .title-4 {
    margin-top:15px;
}
.command-inner .form-date {
    display:inline-block;
    vertical-align:middle;
    width: 145px;
}
.command-inner .form-date + span {
    padding:0 15px;
    display:inline-block;
    vertical-align: top;
    margin-top: 10px;
}
.contact-command-field {
    height:auto;
    margin:0 0 0px;
    padding:15px 40px 15px 15px;
    position:relative;
    min-height:210px;
    color:#414141;
    font-weight:500;
}

.contact-command-field-inner {
    opacity:0;
    visibility:hidden;
}
.editable .contact-command-field-inner {
    opacity:1;
    visibility:visible;
}

.contact-command-field input {
    border:none;
    padding:0;
    height: auto;
    color:#414141;
    font-weight:500;
}
.contact-command-field input[disabled] {
    background-color:transparent;
    cursor:default;
    text-overflow: ellipsis;
}

.contact-command-field .shop-info {
    margin:8px 0;
    padding:4px 0 4px 25px;
    position:relative;

    &.error {
        border: 1px solid red;

        input {
            color: red;

            &::placeholder {
                color: red;
            }
        }

        i {
            color: red;
        }
    }
}
.contact-command-field .shop-name {
    display:block;
    padding:0;
    margin-bottom:5px;
    font-weight:600;
}
.contact-command-field .shop-name input {
    color:#414141;
    font-size:14px;
    font-weight:600;
}
.contact-command-field input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-style:italic;
}
.contact-command-field input::-moz-placeholder { /* Firefox 19+ */
    font-style:italic;
}
.contact-command-field input:-ms-input-placeholder { /* IE 10+ */
    font-style:italic;
}
.contact-command-field input:-moz-placeholder { /* Firefox 18- */
    font-style:italic;
}
.contact-command-field input::placeholder {
    font-style:italic;
}

.contact-command-field.edit .shop-info:before {
    top:12px;
}
.contact-command-field.edit input {
    border: 1px solid #d2d6de;
    padding: 9px 12px;
}

.contact-command-field .shop-info i {
    display:inline-block;
    vertical-align:top;
    margin-top: -3px;
    margin-right:5px;
    position:absolute;
    top:5px;
    left:0;
    font-size: 22px;
    color:#38B66D;
}
.contact-command-field .shop-info.empty i {
    display:none;
}
.contact-command-field.edit .shop-info.empty i {
    display:inline-block;
}

.contact-command-field .shop-address i {
    left:1px;
}
.contact-command-field .shop-phone i {
    left:2px;
}
.contact-command-field .shop-contact-name i {
    font-size: 16px;
    margin-top: 0;
    opacity: 0.85;
}


.contact-command-field textarea {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    padding:15px;

    /*
    visibility:hidden;
    opacity:0;*/
    transition:all 0.3s ease, visibility 0s linear 0.3s;
}
.contact-command-field.edit textarea {
    transition:all 0.3s ease, visibility 0s linear 0s;
    visibility:visible;
    opacity:1;
}


.command-choice {
    margin-top:10px;
}
.command-choice .form-group {
    margin:0;
}
.command-choice label {
    display:block;
    margin:10px 0 !important;
}


/** Step 2 **/
.table-dates.loading {
    position:relative;
}
.table-dates.loading:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
}
.table-dates.loading:before {
    content: "\F110";
    font:normal normal normal 14px/1 FontAwesome;
    position: absolute;
    margin-top:10px;
    left: 50%;
    top: 50%;
    z-index: 1;
    color:#38B66D;
    font-size: 20px;
    animation:Loading 0.7s cubic-bezier(0.62, 0.12, 0.32, 0.98) 0s infinite;
}

.table-dates > thead > tr > th,
.table-dates > thead > tr > th:first-child {
    width:auto;
}
.table-dates > thead > tr > th:last-child {
    width:60px;
}

.table-dates.type2 th:nth-child(2),
.table-dates.type2 th:nth-child(3) {
    width:auto;
}

.ct-form-dates {
    td {
        position:relative;
    }

    &.loading {
        td:after {
            content:'';
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-color:rgba(255,255,255,0.5);
        }
    }

    &.error {
        .form-dates {
            border-color:red;
        }
    }
}

.form-dates {
    display:inline-block;
    vertical-align:top;
    margin-right:30px;
    position:relative;
    border-radius:3px;
    border:1px solid #d2d6de;

    &:after {
        content:"\F061";
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        position:absolute;
        right:-23px;
        top:50%;
        transform: translateY(-50%);
    }

    + .form-dates {
        margin-right:0px;
    }

    ~ .form-dates:after {
        display:none;
    }

    .form-control {
        padding:0 0 0 25px;
        position:relative;
        display:inline-block;
        width:auto;
        border:none;
        height:auto;

        &:before {
            content: '';
            font: normal normal normal 14px/1 FontAwesome;
            font-size: 15px;
            color: #38B66D;
            position:absolute;
            left:10px;
            top:50%;
            transform:translateY(-50%);
        }

        input {
            padding:10px 5px 8px 5px;
            border:none;
            width:auto;

            &[disabled] {
                background-color:transparent;
            }
        }

        &.form-date {
            &:before {
                content: "\F274";
            }

            input {
                width:85px;
            }
        }

        &.form-time {
            &:before {
                content: "\f017";
            }

            input {
                width:50px;
            }

            &.disabled {
                margin-left:-3px;
            }
        }

        &.disabled:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            /* background-color: rgba(255,255,255,0.5); */
            background-color: rgba(200, 200, 200, 0.3);
        }
    }
}

.multiple-no .ct-form-dates:first-child ~ .ct-form-dates .form-date {
    position:relative;
}
.multiple-no .ct-form-dates:first-child ~ .ct-form-dates .form-date:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255,255,255,0.5); */
    background-color: rgba(200, 200, 200, 0.3);
}


.profiles.error select {
    border-color: red;
}


.ui-timepicker-container {
    min-width:80px;
}
.ui-timepicker,
.ui-timepicker-viewport {
    min-width:70px;
}
.ui-timepicker-container .ui-menu-item {
    max-width:100%;
}

.ct-form-dates .ajax-loader {
    display:none;
    margin:10px 0 0 20px;
}
.ct-form-dates.loading .ajax-loader {
    display:inline-block;
    position: relative;
    z-index: 1;
    animation:Loading 0.7s cubic-bezier(0.62, 0.12, 0.32, 0.98) 0s infinite;
}

.table-dates > tbody > tr > .diff-dates {
    padding-left:0;
    text-align:center;
}

.ct-form-dates .diff-dates input {
    border:none;
    max-width:35px;
    text-align:right;
    background:none;
}
.ct-form-dates .diff-dates .duration-type span {
    display:none;
}
.ct-form-dates .diff-dates .duration-type.visible span,
.ct-form-dates .diff-dates .duration-type .visible {
    display:inline;
}

.btn-remove-date {
    text-indent:-9999px;
    position:relative;
    width:30px;
    height:30px;
    border-radius:100%;
    display:block;
    background-color:#c1c1c1;
}
.btn-remove-date i {
    text-indent:0;
    position:absolute;
    top:50%;
    left:50%;
    transform:translateX(-50%) translateY(-50%);
    color:#ffffff;
    font-size:18px;
}


.error-form {
    display:none;
    margin: 15px 0;
    border-radius: 4px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    font-size: 13px;
    padding: 8px 15px;
}

.command-step .form-more-details {
    display:table;
    width:100%;
    max-width:600px;
    margin:40px auto;
}
.command-step .form-more-details label {
    display:table;
    margin:0 auto 5px;
}
.command-step .form-more-details textarea {
    min-height:90px;
}

.field-actions {
    position:absolute;
    top:10px;
    right:10px;
    z-index:1;
    display:none;
}
.editable .field-actions {
    display:block;
}
.field-actions a {
    color:#c1c1c1;
    font-size:20px;
}
.field-actions .close-edit {
    display:none;
}
.field-actions .open-edit {
    display:block;
}
.edit .field-actions .close-edit {
    display:block;
}
.edit .field-actions .open-edit {
    display:none;
}

#command-complement {
    height:70px;
}

.command-inner .form-group {
    padding-left:6px;
    margin-bottom:15px;
}
.command-inner .form-control  {
    font-weight:400;
    box-shadow:0 0 8px 3px rgba(0, 0, 0, 0.04);
}

.command-inner .ct-dropdown {
    width:100%;
}

.command-inner [type=submit] {
    margin:30px auto 50px;
}

.command-step .link-more {
    position:relative;
    outline:0;
    margin:0;
}

.command-step .link-more:before {
    content: "\F067";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 11px;
    width: 16px;
    height: 16px;
    line-height: 17px;
    border-radius: 100%;
    text-align: center;
    background-color: #38B66D;
    color: #ffffff;
    margin-right: 7px;
}
.add-row {
    padding:25px 0 10px;
    border-bottom:2px solid #f1f1f1;
}
.ct-total-time {
    float: right;
    margin-right: 60px;
    padding: 10px 12px;
    border-radius: 0 0 3px 3px;
    background-color: #39b66d;
    color: #ffffff;
    font-weight: 600;
    font-size: 12px;
}
.ct-total-time .plurial {
    display:none;
}
.ct-total-time .plurial.visible {
    display:inline;
}

.order-form-error ul {
    margin: 15px 0;
    border-radius: 4px;
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    display: table;
    font-size: 13px;
}
.order-form-error ul li {
    padding: 8px 15px;
}

.field-error,
.command-error {
    color:red;
    font-size:13px;
    font-style:italic;
    display:none;
    margin-top:5px;
}
.checkable.error {
    border:1px solid red;
}

.command-container .footer-actions {
    display:none;
    clear:both;
}


/** Dropdown bis **/
.dropdown-bis {
    position: relative;
    border-radius: 3px;
    font-size: 13px;
    height: 40px;
    background-color:#ffffff;
    border: 1px solid #d2d6de;
}
.dropdown-bis .dropdown-label {
    display: block;
    padding: 10px 23px 10px 11px;
    cursor: pointer;
    position: relative;
}
.dropdown-bis .dropdown-label:after {
    content: '';
    border-top: 8px solid #38B66D;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    float: right;
    position:absolute;
    right:10px;
    top:15px;
    transition: all 0.25s ease, visibility 0s linear 0.3s;
}
.dropdown-bis.open .dropdown-label:after {
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    transform:rotate(180deg);
    top:14px;
}
.dropdown-bis .dropdown-list {
    display: none;
    background-color: #ffffff;
    position: absolute;
    z-index:1;
    top: 100%;
    padding: 0;
    list-style-type: none;
    left: -1px;
    right: -1px;
    margin: 0;
    border: 1px solid #d2d6de;
    border-radius: 0 0 3px 3px;
    overflow: auto;
    padding-top:10px;
    max-height: 300px;
}
.dropdown-bis.open .dropdown-list {
    z-index:2;
}

.dropdown-bis .dropdown-list li {
    display: block;
    cursor: pointer;
    transition: all 0.08s ease-in-out;
}

.disabled .dropdown-bis {
    background-color:#eee;
    cursor:not-allowed;
}
.disabled .dropdown-bis:after {
    content:'';
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:2;
}

/** Checkbox list **/
.check-list {
    margin:10px 0 30px;
}
.check-list .checklist-item {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
    margin-bottom:10px;
    position: relative;
    font-weight: 600;
    background-color: #ffffff;
    padding: 5px 12px 5px 10px;
    border-radius: 17px;
    font-size: 13px;
}
.check-list .checklist-item .checklist-remove {
    width: 18px;
    height: 18px;
    text-align: center;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    background-color: #38B66D;
    border-radius: 100%;
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    margin-top: -2px;
    cursor: pointer;
    position: relative;
}
.check-list .checklist-item .checklist-remove:before,
.check-list .checklist-item .checklist-remove:after {
    content: '';
    width: 2px;
    height: 10px;
    background-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -1px;
}
.check-list .checklist-item .checklist-remove:before {
    transform:rotate(45deg);
}
.check-list .checklist-item .checklist-remove:after {
    transform:rotate(-45deg);
}

.check-list .checklist-item a {
    color: inherit;
}

.check-list-collapse {
    &.collapsed {
        .check-list {
            &:after {
                content: "...";
            }
        }
        .checklist-item {
            &:nth-child(n+26) {
                display: none;
            }
        }
    }

    &:not(.collapsed) {
        .show-all {
            display: none;
        }
    }
}

/** Forms **/
.shop-contact-group {
    padding-top:20px;
    margin-top:20px;
    border-top:1px solid #d4d4d4;
}

/** Infobulle **/
.infobubble {
    position:relative;
    z-index:3;
}
.infobubble-icon {
    width: 25px;
    height: 25px;
    margin-top: 8px;
    display: inline-block;
    vertical-align: middle;
}
.infobubble-content {
    position: absolute;
    font-size: 11px;
    padding: 10px 10px;
    border-radius: 3px;
    background-color: #ffffff;
    color: #414141;
    min-width: 200px;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%) translateY(10px) scale(0);
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
    opacity:0;
    transition: all .35s cubic-bezier(.3,1.08,.65,1.1);
}
.infobubble-content:after {
    content: '';
    position: absolute;
    left: 50%;
    margin-left: -6px;
    top: 100%;
    border-top: 7px solid #ffffff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
}
.infobubble:hover .infobubble-content {
    transform: translateX(-50%) translateY(0px) scale(1);
    opacity:1;
}

.animation-head-item .infobubble {
    position: absolute;
    right: 0;
    top: 0;
}


/** Dropdown - Liste select déroulante **/
.ct-dropdown {
    position: relative;
    border-radius: 3px;
    font-size: 13px;
    height: 40px;
    border:1px solid #d2d6de;
    z-index: 1;
    display: inline-block;
}
.white .ct-dropdown {
    background-color:#ffffff;
}
.md .ct-dropdown {
    min-width:200px;
}
.ct-dropdown.open {
    z-index:2;
}

.error .ct-dropdown {
    border-color:red;
}

.select-custom {
    position:relative;
}

.select-custom select {
    /*max-width:200px;*/
    width:100%;
    /* width: 75px; */
    padding: 6px 30px 6px 12px;
    font-size: 13px;
    color: #787878;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: transparent;

    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
}

.select-custom select::-ms-expand {
    display: none; /* hiding IE11 arrow */
}

.select-custom.white select {
    background-color: #fff;
}

/* .select-custom:not(.select-custom-2):after {
    content: '';
    border-top: 8px solid #38B66D;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    float: right;
    position:absolute;
    right:10px;
    top:17px;
    transition: all 0.25s ease, visibility 0s linear 0.3s;
}

.select-custom.open:after {
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    transform:rotate(180deg);
    top:16px;
} */

.select2-container .select2-search--inline .select2-search__field {
    padding-right: 15px;
}

.select2-container .select2-search--inline {
    cursor: pointer;
    padding: 9px 23px 9px 12px;
}

.select2-container .select2-search--inline:after {
    content: '';
    border-top: 8px solid #38B66D;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    float: right;
    position:absolute;
    right:10px;
    top:15px;
    cursor: pointer;
    transition: all 0.25s ease, visibility 0s linear 0.3s;
}

.select2-container.select2-container--open .select2-search--inline:after {
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    transform:rotate(180deg);
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color:transparent;
    border-bottom:0;
    border-top: 8px solid #38B66D;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    transform:rotate(180deg);
}

.select-custom.customized:after {
    display:none;
}

.ct-dropdown select,
.select-custom .ct-dropdown select {
    visibility:hidden;
    position:absolute;
    height:0;
    width:0;
    overflow:hidden; 
}

.ct-dropdown .dropdown-label {
    display: block;
    height:100%;
    padding: 9px 30px 10px 11px;
    cursor: pointer;
    position:relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.ct-dropdown .dropdown-label:after {
    content: '';
    border-top: 8px solid #38B66D;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    float: right;
    position:absolute;
    right:10px;
    top:15px;
    transition: all 0.25s ease, visibility 0s linear 0.3s;
}
.ct-dropdown.open .dropdown-label:after {
    -moz-transform:rotate(180deg);
    -webkit-transform:rotate(180deg);
    -o-transform:rotate(180deg);
    -ms-transform:rotate(180deg);
    transform:rotate(180deg);
    top:14px;
}

.ct-dropdown .dropdown-list {
    display: none;
    background-color: #ffffff;
    position: absolute;
    top: 100%;
    padding: 0;
    list-style-type: none;
    left: -1px;
    right: -1px;
    margin: 0;
    border:1px solid #d2d6de;
    border-radius:0 0 3px 3px;
    overflow:auto;
    max-height:300px;
}
.ct-dropdown .dropdown-list li {
    margin-top:1px;
    display: block;
    padding: 8px 18px;
    cursor:pointer;
    transition: all 0.08s ease-in-out;
}
.ct-dropdown .dropdown-list li[rel=""] {
    font-style: italic;
    opacity: 0.4;
    background-color: #efefef;
    cursor:default;
}
/*.ct-dropdown .dropdown-list li a {
    display: block;
    padding: 8px 18px;
    transition: all 0.08s ease-in-out;
}*/
.ct-dropdown .dropdown-list li.dropdown-all {
 
}
.ct-dropdown .dropdown-list li:hover,
.ct-dropdown .dropdown-list li.selected {
    background-color: #38B66D;
    color: #ffffff;
}

.ct-dropdown .dropdown-list li[rel=""]:hover,
.ct-dropdown .dropdown-list li[rel=""].selected {
    background-color:#efefef;
    color:#414141;
}

.disabled .ct-dropdown {
    background-color:#eee;
    cursor:not-allowed;
}
.disabled .ct-dropdown:after {
    content:'';
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index:2;
}



/** Liste number **/
.quantities {
    display: flex;
    align-items: center;
    justify-content: center;
}

.quantities input {
    display:inline-block;
    width: 45px;
    margin: 0 8px;
    text-align: center;
    padding: 0 5px;
}
.quantities input[type=number]::-webkit-inner-spin-button,
.quantities input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
.quantity-control {
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    color: #ffffff;
    background-color: #38B66D;
    width: 19px;
    height: 19px;
    line-height: 18px;
    border-radius: 100%;
    text-align: center;
    cursor: pointer;
}
.disabled .quantity-control {
    background-color:grey;
    cursor:not-allowed;
}

.quantity-control i {
    line-height:21px;
}

.quantity-max-label {
    display: flex;
    justify-content: center;
    gap: 0 5px;
    margin-bottom: 10px;

    span {
        white-space: nowrap;
    }
}

/** Datepicker **/
.datepicker-dropdown {
    padding:10px 20px;
    border-radius:3px;
    border:none;
    box-shadow: 0 5px 6px -3px rgba(0, 0, 0, 0.1), 0 3px 16px 2px rgba(0, 0, 0, 0.12), 0 9px 12px 1px rgba(0, 0, 0, 0.1);
}

input[type=date].form-control, input[type=time].form-control, input[type=datetime-local].form-control, input[type=month].form-control {
    line-height:1;
}

.daterangepicker {
    min-width:330px;
}

.table-condensed {  
    border-spacing:10px 5px;
    border-collapse: initial;
}

.table-condensed>thead>tr>th {
    color:#333333;
    font-weight:600;
    text-transform:uppercase;
    font-size: 12px;
    text-align:center;
}
.table-condensed>thead>tr>th.datepicker-switch {
    font-size:15px;
    color:#38B66D;
}
.table-condensed>thead>tr>th.prev,
.table-condensed>thead>tr>th.next {
    cursor:pointer;
}
.table-condensed>tbody>tr>td {
    text-align:center;
    cursor:pointer;
    border-radius:3px;
    margin:3px;
    color:#212b36;
}
.table-condensed>tbody>tr>td:hover,
.table-condensed>tbody>tr>td.active,
.daterangepicker td.active, 
.daterangepicker td.active:hover {
    background-color:#ededed !important;
    color: #212b36 !important;
}

.table-condensed .month,
.table-condensed .year,
.table-condensed .decade,
.table-condensed .century {
    padding:0 5px;
}



.btn-success {
    position: relative;
    padding: 7px 15px;
    border-radius: 3px;
    font-size:10px;
    font-weight: 700;
    border:none;
    background-color: #95c85d;
    color: #fff;
    text-transform: uppercase;
    overflow: hidden;
    transition: all .25s ease;
}
.btn-success:hover,
.btn-success:focus {
    background-color: #95c85d;
}


.btn-default {
    position: relative;
    padding: 7px 15px;
    border-radius: 3px;
    font-size:10px;
    font-weight: 700;
    border:none;
    background-color: #c1c1c1;
    color: #fff;
    text-transform: uppercase;
    overflow: hidden;
    transition: all .25s ease;
}
.btn-default:hover,
.btn-default:focus {
    background-color: #c1c1c1;
    color:#ffffff;
}


.btn-success:after,
.btn-default:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    opacity: 0.2;
    transition: all 0.35s cubic-bezier(0.74,0.2,0.51,1.04);
}
.btn-success:hover,
.btn-success:focus,
.btn-default:hover,
.btn-default:focus {
    /*opacity:0.8;*/
}
.btn-success:hover:after, 
.btn-success:focus:after,
.btn-default:hover:after, 
.btn-default:focus:after {
    right: -100%;
}

/** Pagination **/
.pagination {
    padding:0 0 20px;
}
.pagination > li,
.pagination > span {
    margin:0 6px 0 0;
    display:inline-block;
}
.pagination > li.disabled {
    opacity:0.7;
}
.pagination > li > a,
.pagination > span > a,
.pagination > span.current {
    background-color:transparent;
    font-size:14px;
    color: #34495E;
    font-weight:600;
    padding: 4px 10px;
    border-radius:100%;
    border:none;
    transition:all 0.25s ease;
}
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > span > a:hover,
.pagination > span > a:focus {
    background-color:#ffffff;
    color: #414141;
}

.pagination > li.active > a,
.pagination > li.active > a:hover,
.pagination > li.active > a:focus,

.pagination > span.current,
.pagination > span.current > a:hover,
.pagination > span.current > a:focus {
    background-color: #38B66D;
    color:#ffffff;
}

.pagination > li.prev > a,
.pagination > li.next > a,

.pagination > span.previous > a,
.pagination > span.next > a,
.pagination > span.first > a,
.pagination > span.last > a {
    text-indent:-9999px;
    font-size:16px;
    background-color:#ffffff;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
    border-radius:100%;
    position:relative;
    color:transparent;
    width: 28px;
    height: 28px;
}

.pagination > span.first > a,
.pagination > span.last > a {
    color: #34495E;
    height: 28px;
    width: 28px;
    font-size: 14px;
    display: block;
    text-indent: 0;
    padding-left: 7px;
    padding-right: 2px;
}

.pagination > li.prev > a:after,
.pagination > li.next > a:after,

.pagination > span.previous > a:after,
.pagination > span.next > a:after {
    content:url(../img/fleche.svg);
    text-indent:0;
    color:#34495E;
    font-size:14px;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
}
.pagination > li.prev > a:after {
    /*content: "\f060";*/
}
.pagination > li.next > a:after,
.pagination > span.next > a:after {
    /*content: "\f061";*/
    transform: rotate(180deg);
    margin-left: -7px;
    margin-top: -7px;
}

.pagination>li:first-child>a, 
.pagination>li:first-child>span,
.pagination>li:last-child>a, 
.pagination>li:last-child>span {
    border-radius:100%;
}

.footer-actions {
    float:right;
}

.footer-actions .cta,
.footer-actions button {
    display:inline-block;
    vertical-align:top;
    margin:0 0 0 10px;
}

.step-actions.disabled, 
.footer-actions.disabled {
    position: relative;
    cursor: not-allowed;
}

.step-actions.disabled:after, 
.footer-actions.disabled:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.form-delete-action {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #dadada;
}
.form-delete-action form {
    float:right;
}

.row-footer {
    margin:20px 0 0;
}
.row-footer.center {
    margin-top:40px;
}

.row-footer .pagination {
    margin:0;
}

.row-footer .cta {
    display:inline-block;
    vertical-align:middle;
    margin:0 10px 0 0;
}
.row-footer form {
    display:inline-block;
}


.table-rights .title-1 {
    font-size:30px;
    color:#34495E;
}
.table-rights .title-2 {
    font-size:20px;
    font-weight:600;
}

.table-rights table {
    /*display:block;*/
    overflow:auto;
}

.table-rights .table-item {
    margin-bottom:40px;
}


.table-rights .switch label:before {
    /*background-color: #46617c;*/
}
.table-rights .switch input:checked + label:before {
    
}




.table-container {
    position:relative;
}
.table-fixed {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    border-right: 1px solid #e0e0e0;
    display:none;
}
.table-empty {
    background-color:#34495E;
    /*border-bottom: 2px solid #e0e0e0;*/
}
.table-cell {
    background-color:#ffffff;
    border-top: 1px solid #e0e0e0;
}

.table-cell .title-2 {
    color: #656d7c;
    margin-bottom:0;
}

.table-container.view .table-fixed {
    display:block;
    box-shadow: 3px 0 10px 0px rgba(0, 0, 0, 0.08);
}
.table-container.view .table-empty {
    /*border-bottom: 2px solid #e0e0e0;*/
}
.table-container.view .table-cell {
    padding:15px;
    display:flex;
    align-items:center;
}

/** Création user **/
#user1_businessUnit input[type="checkbox"] {
    display:none;
}
#user1_businessUnit input[type="checkbox"] + label {
    position:relative;
    padding-left:55px;
    cursor:pointer;
    margin:8px 0;
    padding-top:2px;
    font-size:13px;
    font-weight:600;
}
#user1_businessUnit input[type="checkbox"] + label:before {
    content:'';
    width: 45px;
    height: 24px;
    border-radius: 14px;
    /*background-color: #46617c;*/
    background-color: #dadada;
    border:1px solid rgba(255, 255, 255, 0.1);
    position:absolute;
    left:0;
    top:0;
    transition:all 0.2s ease;
}
#user1_businessUnit input[type="checkbox"] + label:after {
    content:'';
    width: 16px;
    height: 16px;
    border-radius: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position:absolute;
    left:3px;
    top:4px;
    transition:all 0.2s ease;
}
#user1_businessUnit input[type="checkbox"]:checked + label:before {
    background-color:#38B66D;
}
#user1_businessUnit input[type="checkbox"]:checked + label:after {
    transform:translateX(22px);
}

.btn-right {
    float:right;
}
.btn-right .cta {
    display:inline-block;
    vertical-align:middle;
    margin:0 0 0 10px;
}

.user-edit-footer .select2 {
    min-width:200px;
    margin: 5px 25px 5px 0;
}

.form-user-edit legend {
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 20px;
}
.last-bloc-user {
    margin-top:30px;
}


/** Page Type opération **/
.operation-type-list > div > label {
    font-size: 26px;
    font-weight: 500;
    margin-bottom:0px;
    margin-top:15px;
}


.table-user-info {
    min-width:100%;
    display:block;
    overflow:auto;
}
.table-user-info td {
    min-width:150px;
}

.form-translation > div {
    margin-bottom:20px;
    display: inline-block;
    vertical-align:top;
    margin-right: 20px;
}

.form-translation input[type="text"] {
    border-radius:3px;
    font-size:13px;
    font-weight:600;
    height:40px;
    color:#34495E;
    padding: 9px 12px;
    border:1px solid #d2d6de;
    min-width:185px;
}
.form-translation label {
    display:block;
    margin-bottom:3px;
}
.form-translation .ct-dropdown {
    min-width:185px;
}
.form-translation .dropdown-label {
    background-color:#ffffff;
}

.form-translation button {
    background-color: #38B66D;
    color: #ffffff;
    position: relative;
    display: table;
    padding: 12px 25px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    border:none;
    margin-top:17px;
    transition: all 0.25s ease;
}

.form-translation button:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    opacity: 0.2;
    transition: all 0.35s cubic-bezier(0.74,0.2,0.51,1.04);
}
.form-translation button:hover,
.form-translation button:focus {
    /*opacity:0.8;*/
}
.form-translation button:hover:after, 
.form-translation button:focus:after {
    right: -100%;
}


.no-results {
    color: #34495E;
    font-weight: 600;
    margin:10px 0 0;
}


/** Message validation / erreurs **/
.flash-success,
.flash-error {
    position:fixed;
    z-index:3;
    top:60px;
    left:50%;
    transform:translateX(-50%);
    border-radius:4px;
    animation:fadeDelay 0.7s ease-in-out 3s 1 both alternate;
}
.flash-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
    padding: 15px;
    margin-bottom: 40px;
}

.flash-error {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    padding: 15px;
    margin-bottom: 15px;
}

@-moz-keyframes fadeDelay {
    0% { visibility:visible;opacity:1; }
    99% { opacity:0; }
    100% { visibility:hidden;opacity:0;display:none; }
}
@-webkit-keyframes fadeDelay {
    0% { visibility:visible;opacity:1; }
    99% { opacity:0; }
    100% { visibility:hidden;opacity:0;display:none; }
}
@-o-keyframes fadeDelay {
    0% { visibility:visible;opacity:1; }
    99% { opacity:0; }
    100% { visibility:hidden;opacity:0;display:none; }
}
@-ms-keyframes fadeDelay {
    0% { visibility:visible;opacity:1; }
    99% { opacity:0; }
    100% { visibility:hidden;opacity:0;display:none; }
}
@keyframes fadeDelay {
    0% { visibility:visible;opacity:1; }
    99% { opacity:0; }
    100% { visibility:hidden;opacity:0;display:none; }
}




/*** LISTE DES COMMANDES ***/
.step-status {
    display: inline-block;
    padding: 4px 12px 5px 25px;
    border-radius: 13px;
    background-color: #38B66D;
    color: #ffffff;
    font-size: 12px;
    font-weight: 600;
    position: relative;
}
.step-status:before {
    content: '\2022';
    position: absolute;
    left: 12px;
    top: 2px;
    color: #ffffff;
    font-size: 17px;
}
.table-resize .step-status {
    white-space:nowrap;
}

.datepicker .day.disabled{
    opacity: 0.2;
    pointer-events: none;
}
.remove-date-0 {
    display: none;
}
.order-form-error ul :first-child{
    display: none;
}

.quota-container ul {
    width: 100%;
}
/*.quota-container ul li {
    width: 25%;
    display: block;
    float: left;
}*/


/*** Page quotas ***/
.quota-max-nb {
    font-weight: 700;
    margin-bottom: 15px;
}

@media all and (max-width:767px){
    #change-refrigerator-bin .ct-dropdown-bis,
    .quota-container .select-custom{
        width: 100%;
    }
}

.quota-container {
    /*padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid #e0e0e0;*/
    margin-top:20px;
}

.quota-container > .items.loading {
    position:relative;
}
.quota-container > .items.loading:before {
    content:'';
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color:#ffffff;
    opacity:0.5;
    z-index:2;
}
.quota-container > .items.loading:after {
    content: "\F110";
    font: normal normal normal 14px/1 FontAwesome;
    position:absolute;
    z-index:4;
    left:50%;
    top:50%;
    /*top:40px;*/
    font-size: 20px;
    color:#38B66D;
    animation:Loading 0.7s cubic-bezier(0.62, 0.12, 0.32, 0.98) 0s infinite;
}


.quota-cat {
    font-weight:700;
    text-transform:uppercase;
    color: #787878;
    font-size: 11px;
    margin-bottom:8px;
}

.quota-head {
    position:relative;
    padding:15px 15px;
    text-align: center;
}

.quota-head .quota-label,
.quota-head .quota-inputs {
    display:inline-block;
}

.quota-head .quota-label,
.national > .quota-head > span {
    position:absolute;
    left:15px;
    top:50%;
    transform:translateY(-50%);
    text-align:left;
}

.quota-head .quota-label .ct-qAssigned,
.national > .quota-head > span .ct-qAssigned {
    font-size:0.85em;
}

.quota-inputs {
    display: table;
    margin:auto;
    position:relative;
}
.quota-head + ul {
    padding:0 20px;
}

.quota-inputs > div {
    margin: 0 0 0 20px;
    display:inline-block;
}
.only-national .quota-inputs > div {
    width:180px;
}

.quota-inputs > div:first-child {
    margin: 0;
}

.quota-inputs > div input.error {
    border-color:red;
}

.quota-allocate {
    display: inline-block;
    position: absolute;
    left: 100%;
    top: 50%;
    margin-top: -15px;
    white-space: nowrap;
    width: auto;
    margin-left: 10px;
}
.niv-2 .quota-allocate {
    display:none;
}
.niv-2 .ct-qAssigned {
    display:none;
}


.quota-error,
.quota-inputs .quota-error {
    display:none;
    color:red;
    font-style:italic;
    font-size:12px;
    margin: 8px 0 0;
    font-weight: 500;
}

@media (max-width: 480px){
    .quota-error,
    .quota-inputs .quota-error {
        max-width: 100%;
    }
}

.quota-inputs .quota-error[data-assigned],
.quota-inputs .quota-error[data-reserved],
.quota-inputs .quota-error[data-consumed] {
    display:block;
}

.national > .quota-head .quota-allocate {
    margin-top:-5px;
}

.national {
    border-left: 3px solid #34495E;
}
.national > .quota-head {
    text-align:center;
    background-color: #34495E;
}
.national > .quota-head .quota-cat {
    color:#ffffff;
}

.national > .quota-head .quota-inputs {
    /*background: #e0e0e0;
    padding: 10px;
    display: block;
    text-align: center;*/
    display:inline-block;
}
.national > .quota-head > span {
    font-size:17px;
    font-weight: 700;
    text-transform: uppercase;
    
    color: #ffffff;
    display: inline-block;
    padding: 12px 15px;
}
/*.national > span:after {
    content:'+';
    float:right;
}*/

li.quota-item.niv-0 > .quota-head .quota-btn,
li.quota-item.niv-1 > .quota-head .quota-btn {
    cursor:pointer;
    width:20px;
    height:20px;
    line-height:20px;
    text-align:center;
    font-size:16px;
    font-weight:700;
    border-radius:100%;
    background-color:#ffffff;
    color:#414141;
    position:absolute;
    right:15px;
    top:50%;
    transform:translateY(-50%);
}

li.quota-item.niv-0 > .quota-head .quota-btn:after,
li.quota-item.niv-1 > .quota-head .quota-btn:after {
    content:'+'; 
}


li.quota-item.niv-0.open > .quota-head .quota-btn,
li.quota-item.niv-1.open > .quota-head .quota-btn {
    line-height: 18px;
}
li.quota-item.niv-0.open > .quota-head .quota-btn:after,
li.quota-item.niv-1.open > .quota-head .quota-btn:after {
    content:'-';
}

.region-tree .status-item:before {
    margin-top: 13px;
    margin-left: 10px;
    width: 8px;
    height: 8px;
}

/* 1er niveau */
li.quota-item.niv-0 {
    margin-top: 35px;
    border-left: 3px solid #e0e0e0;
}

li.quota-item.niv-0 > .quota-head {
    font-size: 14px;
    font-weight: 700;
    background-color: #e0e0e0;
    /*color: #ffffff;*/
    width: 100%;
    display: block;
    padding: 8px 15px;
    text-align:center;
}

li.quota-item.niv-0 > .quota-head .quota-inputs {
    /*background: #e0e0e0;*/
}


/* 2e niveau */
li.quota-item.niv-1 {
    margin-top: 15px;
    border-left: 3px solid #e0e0e0;
}
li.quota-item.niv-1:first-child {
    /*margin-top:0;*/
}

li.quota-item.niv-1 > .quota-head  {
    font-size: 13px;
    font-weight: 700;
    background-color: #e0e0e0;
    /*color: #ffffff;*/
    width: 100%;
    display: block;
    padding: 8px 15px;
    text-align:center;
}


li.quota-item.niv-1 > .quota-head .quota-inputs {
    /*background: #e0e0e0;*/
    padding: 10px;
}

li.quota-item.niv-0 > ul,
li.quota-item.niv-1 > ul {
    display: none;
}

.quota-total {
    margin: 0px 0 30px;
    background-color: #34495E;
    padding: 20px;
    color: #ffffff;
    /* border-top: 1px solid #e0e0e0; */
}
.quota-total .title-2 {
    margin-bottom:8px;
}

.quota-total-flex {
    display:flex;
}
.quota-total-flex .sep {
    margin:0 10px;
}

.total-label {
    /*color: #787878;*/
    /*color:#ffffff;*/
    font-size: 11px;
    margin-bottom: 5px;
    font-weight: 700;
    text-transform: uppercase;
}
.total-label + span {
    font-weight:600;
    margin: 0;
    padding: 0;
    font-size: 14px;
    /*color: #414141;*/
}

.quota-btn.all {
    cursor:pointer;
    float:right;
    display: inline-block;
    margin-top:25px
}

.quota-big-list {
    max-height:150px;
    overflow:auto;
}

.quota-allocatenivs-container{
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
}

.quota-allocatenivs-container .select-custom{
    display: flex; 
}

.quota-allocatenivs-container .form-group:last-child{
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
}

.swith-quotarefbin.tabs-custom .nav-tabs{
    flex-wrap: wrap;
} 
.swith-quotarefbin.tabs-custom{
    padding-bottom: 1px;
}

.quota-container label{
    color: #fff;
}

.quota-allocatenivs-container li,
.quota-allocatenivs-container .dropdown-label{
    color: #656d7c;
}
/************************
****** REGION TREE ****** 
*************************/
.region-tree li a {
    color:#414141;
}

.region-tree li.niv-0 {
    margin:0 0 20px;
    border-left: 3px solid #34495E;
}

.region-tree li.niv-0 > .region-head {
    padding: 12px 15px;
    /*text-align:center;*/
    background-color: #34495E;
}
.region-tree li.niv-0 > .region-head > a {
    color:#ffffff;
    font-weight: 700;
    font-size:17px;
    text-transform: uppercase; 
}

.region-tree li.niv-1 {
    margin-top: 20px;
    border-left: 3px solid #e0e0e0;
}
.region-tree li.niv-1 > .region-head {
    background-color: #e0e0e0;
    width: 100%;
    padding: 8px 15px;
}
.region-tree li.niv-1 > .region-head > a {
    font-size: 14px;
    font-weight: 700;
}

.region-tree li.niv-2 {
    /*margin-top: 15px;
    border-left: 3px solid #e0e0e0;*/
}
.region-tree li.niv-2 > .region-head {
    /*font-size: 13px;
    font-weight: 700;
    background-color: #e0e0e0;
    width: 100%;
    display: block;
    padding: 8px 15px;*/
    padding:15px 0;
    border-top:1px solid #e0e0e0;
}
.region-tree li.niv-2:first-child > .region-head {
    border-top:none;
}

.region-tree li.niv-3 {
    padding: 15px 15px;
}

.region-tree li .region-head + ul {
    padding: 0 15px;
}
.region-head {
    padding:5px;
}
.region-head .box-actions {
    float:right;
}
.region-head > a {
    margin-top:5px;
    display:inline-block;
}

.region-btn-slide {
    cursor: pointer;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    border-radius: 100%;
    background-color: #ffffff;
    color: #414141;
    float:right;
    margin: 5px 20px 0 0;
}
.region-btn-slide:after {
    content: '+';
}

li.open > .region-head .region-btn-slide {
    line-height: 18px;
}
li.open > .region-head .region-btn-slide:after {
    content: '-';
}

.region-tree li.niv-2 .region-btn-slide {
    display:none;
}

.region-tree li.niv-0 > ul,
.region-tree li.niv-1 > ul {
    display:none;
}

.region-btn-all {
    float:right;
    cursor:pointer;
    margin:20px 0 15px;
}


/** COMMENTAIRES **/
.order-comment {
    margin-bottom:20px;
}
.order-comment-head {
    color: #787878;
    font-size: 11px;
    margin-bottom: 5px;
    font-weight: 700;
    text-transform: uppercase;
}
.order-comment .order-comment-date,
.order-comment .order-comment-user {
    display:inline-block;
    vertical-align:middle;
}
.order-comment-message {
    border-left: 1px solid #d4d4d4;
    padding-left: 10px;
    margin-top: 7px;
    font-size: 13px;
    font-style: italic;
}

.order-comment-container h3 {
    /*color: #787878;
    font-size: 11px;
    margin-bottom: 5px;
    font-weight: 700;
    text-transform: uppercase;*/
    margin-bottom:15px;
}

.order-comment-add [type="submit"] {
    position: relative;
    display: table;
    color:#ffffff;
    font-size: 10px;
    padding: 8px 15px;
    border-radius: 3px;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    border:none;
    transition: all 0.25s ease;
}
.order-comment-add [type="submit"]:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    opacity: 0.2;
    transition: all 0.35s cubic-bezier(0.74,0.2,0.51,1.04);
}
.order-comment-add [type="submit"]:hover:after, 
.order-comment-add [type="submit"]:focus:after {
    right: -100%;
}

.order-comment-add + .order-comments {
    border-top:1px solid #d4d4d4;
    padding-top:25px;
    margin-top:10px;
}

.order-comment-add,
.order-comments {
    display:none;
}

.order-comment-container {
    position:relative;
}
.order-comment-container.loading:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    opacity: 0.5;
}



/** Jours fériés **/
.group-dateTimestamp {
    visibility: hidden;
    height: 0;
    overflow: hidden;
    padding: 0;
    margin: 0;
}
.group-fakedate label:after {
    content: '*';
    color: red;
    margin-left: 3px;
}

/** Page view operation **/
.table-operation-view {
    overflow-x:auto;
    display:block;
}
.table-operation-view th,
.table-operation-view td {
    min-width:200px;
}
.group-view {
    margin:30px 0;
}
.group-view.center {
    text-align:center;
}

.group-view .title-4 {
    margin-bottom:5px;
}

.operation-visuel {
    margin-top:10px;
}


/** Page reset password **/
.bloc-reset-password #reset_password_form > div {
    margin-bottom: 15px;
}
.bloc-reset-password label {
    color: #787878;
    font-size: 11px;
    margin-bottom: 5px;
    font-weight: 700;
    display:block;
    text-transform: uppercase;
}
.bloc-reset-password label + input {
    border-radius: 3px;
    font-size: 13px;
    font-weight: 600;
    height: 40px;
    color: #787878;
    padding: 9px 12px;
    border:1px solid #d2d6de;
}

.bloc-reset-password button {
    position: relative;
    display: table;
    padding: 12px 25px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    overflow: hidden;
    transition: all 0.25s ease;
    background-color: #38B66D;
    color: #ffffff;
    border: none;
}

.bloc-reset-password button:after {
    content: '';
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: #ffffff;
    opacity: 0.2;
    transition: all 0.35s cubic-bezier(0.74,0.2,0.51,1.04);
}
.bloc-reset-password button:hover,
.bloc-reset-password button:focus {
    /*opacity:0.8;*/
}
.bloc-reset-password button:hover:after, 
.bloc-reset-password button:focus:after {
    right: -100%;
}


/** Détails opération **/
.tabs-custom {
    padding-bottom:30px;
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
}

.tabs-custom .tab-content {
    padding:0 45px;
    margin-top: 30px;
}
.tabs-custom.white {
    background-color:#ffffff;
}

.tabs-custom-head {
    background-color:#34495E;
    color:#ffffff;
    font-size: 20px;
    font-weight:600;
    text-align:center;
    padding:18px 15px;
    text-transform:uppercase;
}

.tabs-custom .nav-tabs>li>a, 
.tabs-custom .nav-tabs>li>a:focus, 
.tabs-custom .nav-tabs>li>a:hover,

.tabs-custom .nav-tabs>li.active>a, 
.tabs-custom .nav-tabs>li.active>a:focus, 
.tabs-custom .nav-tabs>li.active>a:hover {
    background-color:transparent !important;
    border-left:none !important;
    border-right:none !important;
    border-left-color:transparent;
    border-right-color:transparent;
    border-bottom-width:4px;
}

.tabs-custom .nav-tabs {
    display: flex;
    justify-content: space-around;
    margin: 0 auto 30px;
    border:none;
}

.tabs-custom .nav-tabs li {
    display: inline-block;
    vertical-align: top;
    margin: 0 15px;   
}

.tabs-custom .nav-tabs li a {
    display:block;
    text-align:center;
    padding: 25px 40px 15px;
    color: #ababab;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    border-bottom: 4px solid #eeeeee;
    opacity: 0.4;
    transition: all 0.25s ease;
}
.tabs-custom .nav-tabs li a:hover {
    background-color:transparent !important;
    color: #38B66D;
    border-bottom-color: #38B66D;
    opacity:1;
}

.tabs-custom .nav-tabs li.active a {
    color: #38B66D;
    border-bottom-color: #38B66D;
    opacity:1;
    transition: all 0.25s ease;
}



/*** PAGES EDITION ***/
.form-group-img a {
    /*display:table;*/
}
.form-group-img img {
    max-width:300px;
}

.form-img {
    margin:20px 0 10px;
}

.form-group-img .vich-image > div {

}


.form-group-img .vich-image > div input[type="checkbox"] {
    display:none;
}

.form-group-img .vich-image > div input[type="checkbox"] + label {
    position:relative;
    padding-left:55px;
    cursor:pointer;
    margin:8px 0;
    padding-top:2px;
    font-size:13px;
    font-weight:600;
    text-transform:none;
    color:#414141;
}

.form-group-img .vich-image > div input[type="checkbox"] + label:before {
    content:'';
    width: 45px;
    height: 24px;
    border-radius: 14px;
    /*background-color: #46617c;*/
    background-color: #dadada;
    border:1px solid rgba(255, 255, 255, 0.1);
    position:absolute;
    left:0;
    top:0;
    transition:all 0.2s ease;
}
.form-group-img .vich-image > div input[type="checkbox"] + label:after {
    content:'';
    width: 16px;
    height: 16px;
    border-radius: 14px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    position:absolute;
    left:3px;
    top:4px;
    transition:all 0.2s ease;
}
.form-group-img .vich-image > div input[type="checkbox"]:checked + label:before {
    background-color:#38B66D;
}
.form-group-img .vich-image > div input[type="checkbox"]:checked + label:after {
    transform:translateX(22px);
}

.form-group-file .form-attachment-rest {
    margin:15px 0;
}
.form-group-file .form-attachment-rest label {
    display:none;
}

.form-group-file .attachment-wrapper {
    display:none;
}
.form-group-file .attachment-wrapper.new-attachment {
    display:block;
    margin-bottom:30px;
}

.form-group-file .attachment-wrapper.new-attachment .btn {
    margin-top:15px;
}

.form-group-file .form-file {
    margin:20px 0;
}

.attachment-link {
    display:inline-block;
    font-size: 14px;
    font-weight: 600;
    color:inherit;
    position:relative;
    padding-bottom:1px;
}
.attachment-link:hover,
.attachment-link:focus {
    color:inherit;
}
.attachment-link:after {
    content:'';
    position:absolute;
    bottom:0;
    left:17px;
    right:0;
    height:2px;
}

.attachment-link i {
    margin-right:5px;
}

.attachment-link + .form-delete-file {
    margin:0 0 0 20px;
}
.form-custom.attribute-furnitures form .form-group-custom {
    display: none;
}
.m-20 {
    margin: 20px;
}

.mt-15 {
    margin-top: 15px;
}

.pb-50 {
    padding-bottom: 50px;
}


/** FURNITURES **/
.ct-cart-add,
.ct-cart-delete {
    text-align: center;
}

.cart-add,
.cart-delete {
    width: 100%;
}

.furniture-row {
    .furniture-icon-check {
        display: none;
        color: #38B66D;
    }

    &.selected {
        .furniture-icon-check {
            display: inline-block;
            margin-right: 10px;
            font-size: 16px;
        }
    }
}

.calendar-custom {
    table {
        min-width: 230px;
    }

    .calendar-head {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #34495E;
        color: white;
        font-size: 13px;
        text-transform: uppercase;
        position: relative;
        min-height: 35px;
    }

    .calendar-arrow {
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: inherit;
        font-size: 18px;
        position: absolute;
        top: 1px;

        &.calendar-prev {
            left: 0;
        }

        &.calendar-next {
            right: 0;
        }
    }

    tbody {
        border: 1px solid #e0e0e0;
    }

    .calendar-cell {
        padding: 8px 5px;

        @media(min-width: 500px) {
            min-width: 45px;
        }
    }

    .calendar-day {
        display: flex;
        flex-direction: column;
        align-items: center;

        .calendar-day-name {
            font-weight: 700;
            margin-bottom: 2px;
            opacity: 0.6;        
        }

        .calendar-day-num {
            font-size: 13px;
        }
    }

    .calendar-stock {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        font-weight: 700;
        font-size: 13px;
        background-color: #38B66D;
        color: white;
        border-radius: 5px;
        padding: 4px;
        min-width: 20px;
        margin: auto;

        &.empty {
            background-color: #979797;
        }
    }

    .calendar-row {
        &.dates {
            .calendar-cell {
                padding: 8px 5px 3px;
            }
        }
    }

    + .calendar-custom {
        margin-top: 30px;
    }    
}

.calendar-input-date {
    margin: 0 0 15px;
}

.calendar-title {
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;

    .icon {
        margin-right: 5px;
        color: #38B66D;
    }
}

.calendar-add,
.calendar-delete {
    margin: 15px 0 0;
}

.calendar-custom-period {
    margin-top: 15px;
}

.product-calendar {
    display: inline-flex;
    flex-direction: column;
    margin-bottom: 40px;

    &.animation-info-command {
        color: inherit;
        max-width: none;
        padding: 15px 40px;
    }

    @media(max-width: 1399px) {
        &.animation-info-command {
            padding: 15px 20px;
        }
    }

    @media(max-width: 400px) {
        &.animation-info-command {
            border: none;
            padding: 0;
        }
    }
}

.calendar-step3 {
    .product-calendar {
        .calendar-delete {
            display: none;
        }
    }
}

.furniture-container-wrapper {
    position: relative;

    .furniture-refresh-action {
        display: none;
        position: relative;

        .field-error {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            text-align: center;
            margin-top: 20px;
        }
    }

    &.refresh {
        .next-step {
            display: none !important;
        }

        .furniture-refresh-action {
            display: block;
            margin: 50px auto 0;
        }
    }

    .flash-furnitures {
        position: absolute;
        top: 0;
    }
}

.furniture-check {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 70px;
    height: 70px;
    font-size: 20px;
    opacity: 0.8;
    color: white;
    background-color: #38B66D;
}

.furniture-teaser-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    padding: 30px;

    .furniture-check {
        display: none;
    }

    .product-img {
        position: relative;
        text-align: center;
        width: 200px;
        flex: 0 0 auto;

        img {
            max-width: 100%;
        }
    }

    .product-info {
        text-align: center;
        margin: 20px 0 0;
    }

    @media(min-width: 1250px) and (max-width: 1499px) {
        .product-img {
            width: 120px;
        }
    }

    @media(max-width: 1249px) {
        flex-direction: column;
    }

    @media(max-width: 400px) {
        padding: 30px 15px;
    }
}

.furniture-teaser {
    box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.08);

    + .furniture-teaser {
        margin-top: 50px;
    }

    &.isInOrder {
        .furniture-check {
            display: flex;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.furnitures-list-wrapper {
    margin-top: 20px;
}

.furniture-pagination {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    .col-md-10 {
        width: auto;
    }

    &.top {
        .row-footer {
            margin: 0;

            @media(max-width: 767px) {
                margin: 0 auto;
            }
        }

        .furniture-refresh-action {
            margin: 0 0 15px;
            
            @media(min-width: 768px) {
                text-align: right;
            }
    
            .field-error {
                position: static;
            }
        }
    }

    @media(max-width: 767px) {
        &.top {
            justify-content: center;
            align-items: center;
        }
    }
}

.table-furniture-description {
    .furniture-description-icon {
        display: none;
    }

    @media(min-width: 992px) {
        position: relative;
        display: table;
        position: relative;

        .furniture-description-icon {
            display: inline-flex;
            align-items: center;
            gap: 0 10px;
        }
        
        .furniture-description-infobulle {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            min-width: 400px;
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: white;
            padding: 25px;
            box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
            cursor: default;
            visibility: hidden;
            opacity: 0;
            transition: all 0.15s ease-in-out, visibility 0s 0.15s;
        }

        &:hover {
            .furniture-description-infobulle {
                transition: all 0.15s ease-in-out, visibility 0s 0s;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}

.ct-table-sorting {
    @media(max-width: 1699px) {
        .ct-form-sbf {
            td:first-child {
                img {
                    max-width: 150px;
                }
            }
        }

        .product-calendar {
            &.animation-info-command {
                padding: 15px 20px;
            }
        }
    }
}

.form-dates-furnitures {
    @media(max-width: 767px) {
        padding: 0 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

.form-recovery-date {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

td.form-recovery-date {
    display: table-cell;
}


// Zoom
.medium-zoom--opened {
    .medium-zoom-overlay {
        z-index: 10000;
    }
}
.medium-zoom-image--opened {
    z-index: 10001;
}

img[data-zoomable] {
    &.zooming {
        position: relative;
        z-index: 10001;
    }
}


.form-attachment-rest-custom {
    .help-text {
        display: none;
    }
}

.form-help-custom {
    margin-top: 10px;
}

.error-form-custom {
    ul {
        font-weight: 700;
        margin-bottom: 10px;
        display: block;
    }
}

.form-group-img {
    small {
        display: block;
        margin-top: 5px;
    }
}

.form-file-custom {
    padding-left: 15px;
    border-left: 1px solid #d2d6de;
}

.wrapper-custom-shop {
    box-shadow: 0 0 14px 3px rgba(0, 0, 0, 0.09);
    padding: 20px 30px;
    display: none;
}