.header-1 {
	background-position:center;
	background-repeat:no-repeat;
	background-size:cover;
	margin: -22px -30px 0;
	padding:80px 30px 30px;
	min-height:400px;
	color:#ffffff;
	position:relative;
}

@media(max-width:767px) {
	.header-1 {
		min-height:250px;
		margin: -22px -15px 0;
	}
}

.header-1.home {
	padding:30px 30px 30px;
	/*background-image:url('../img/visuel-accueil.jpg');*/
}
.header-1.dashboard {
	/*background-image:url('../img/visuel-dashboard.jpg');*/
}

.header-1.encart {
	padding-bottom:130px;
}

.header-1:not(.header-article):before {
	content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
	background: linear-gradient(to top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 100%);
}

.header-title {
	font-size:50px;
	font-weight:600;
	margin-bottom:20px;
}

@media(max-width:991px) {
	.header-title {
		font-size:40px;
	}
}
@media(max-width:767px) {
	.header-title {
		font-size:34px;
	}
}

.header-1.home .header-title:before {
	content:url('../img/icons/icon-actus.svg');
	display:table;
	margin:0 auto 15px;
	border-radius:100%;
	width:75px;
	height:75px;
    line-height: 75px;
	text-align:center;
	font-size:42px;
}

.header-intro {
	font-size:20px;
	font-weight:600;
}

@media(max-width:991px) {
	.header-intro {
		font-size:16px;
	}
}

.header-1 .cta {
	margin:20px auto 0;
}

.header-1 .box-actions {
	float:right;
}
.header-1 .box-actions > ul {
	right: 100%;
	left:auto;
	transform:none;
	margin-top: -15px;
    margin-right: -15px;
}

.ct-section {
	margin:40px 0;
}
@media(max-width:580px) {
	.ct-section {
		margin-left:-15px;
		margin-right:-15px;
	}
}

.headline {
	background-color:#ffffff;
	box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.05);
	border-radius:5px;
	padding:30px;
	position:relative;
}

.headline.headline-cat {
	max-width:600px;
	width:100%;
	margin-left:auto;
	margin-right:auto;
}

@media(max-width:580px) {
	.headline,
	.headline.headline-cat {
		padding:30px 15px;
		margin-left:-15px;
		margin-right:-15px;
		width:auto;
	}
}

.headline-inner {
	/*max-width:750px;
	margin:auto;*/
}

.bloc-up {
	margin-top: -100px;
}


/**** DASHBOARD ****/
.dashboard-choice {
	display:flex;
	justify-content:center;
	margin:30px auto 0px;
}
.dashboard-choice.choice-1 {
	padding-bottom:20px;
	border-bottom:4px solid #d8d8d8;
	margin:30px auto 30px;
}
@media(max-width:767px) {
	.dashboard-choice.choice-1 {
		margin:30px auto 0;
	}
}


.dashboard-choice li {
	margin:0 20px;
}
.dashboard-choice li a {
	font-size:16px;
	font-weight:700;
	color:#7f8389;
	text-transform:uppercase;
	transition:color 0.3s ease;
}
.dashboard-choice li a:before {
	content:'';
	display:inline-block;
	vertical-align:middle;
	width:75px;
	height:75px;
	margin-right:15px;
	border-radius:100%;
	background-color:#ffffff;
	background-repeat:no-repeat;
	background-position:center;
	transition:background-color 0.3s ease;
}

.dashboard-choice.choice-2 li a:before {
	background-color:#f3f3f3;
}


.dashboard-choice li .dash-merch:before {
	background-image:url('../img/icons/icon-merch-gris.svg');
}
.dashboard-choice li .dash-anim:before {
	background-image:url('../img/icons/icon-anim-gris.svg');
}


.dashboard-choice li a:hover,
.dashboard-choice li a.active {
	color:#414141;
}
.dashboard-choice li a:hover:before,
.dashboard-choice li a.active:before {
	background-color:#38B66D;
}
.dashboard-choice li .dash-merch:hover:before,
.dashboard-choice li .dash-merch.active:before {
	background-image:url('../img/icons/icon-merch-blanc.svg');
}
.dashboard-choice li .dash-anim:hover:before,
.dashboard-choice li .dash-anim.active:before {
	background-image:url('../img/icons/icon-anim-blanc.svg');
}

.dashboard-choice .familly-choose .switch {
	display:inline-block;
	margin:0 20px;
}

@media(max-width:580px) {
	.dashboard-choice .familly-choose .switch {
		display:table;
		margin:20px auto;
	}
}

.dashboard-choice .familly-choose .switch label {
	font-size:16px;
	font-weight:700;
	color:#7f8389;
	text-transform:uppercase;
	padding-left:0;
	transition:color 0.3s ease;
}
.dashboard-choice .familly-choose .switch label:before {
	content:'';
	display:inline-block;
	vertical-align:middle;
	position:relative;
	width:75px;
	height:75px;
	margin-right:15px;
	border-radius:100%;
	background-color:#ffffff;
	background-repeat:no-repeat;
	background-position:center;
	transition:background-color 0.3s ease;
}

.dashboard-choice.choice-2 .familly-choose .switch label:before {
	background-color:#f3f3f3;
}

.dashboard-choice .familly-choose .switch label[for="operation_family_dashboard_operationFamily_10"]:before {
	background-image:url('../img/icons/icon-anim-gris.svg');
}
.dashboard-choice .familly-choose .switch label[for="operation_family_dashboard_operationFamily_11"]:before {
	background-image:url('../img/icons/icon-merch-gris.svg');
}

.dashboard-choice .familly-choose .switch label:after {
	display:none;
}


.dashboard-choice .familly-choose .switch label:hover,
.dashboard-choice .familly-choose .switch input:checked + label {
	color:#414141;
}

.dashboard-choice .familly-choose .switch label[for="operation_family_dashboard_operationFamily_11"]:hover:before,
.dashboard-choice .familly-choose .switch input:checked + label[for="operation_family_dashboard_operationFamily_11"]:before {
	background-image:url('../img/icons/icon-merch-blanc.svg');
}
.dashboard-choice .familly-choose .switch label[for="operation_family_dashboard_operationFamily_10"]:hover:before,
.dashboard-choice .familly-choose .switch input:checked + label[for="operation_family_dashboard_operationFamily_10"]:before {
	background-image:url('../img/icons/icon-anim-blanc.svg');
}

.dashboard-choice .familly-choose button[type="submit"] {
	display:none;
}

/**** TEASER ****/
.teaser {
	box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
  	background-color: #ffffff;
  	position:relative;
    margin: 10px 0 30px;
}
.teaser .box-actions {
	position:absolute;
	right:15px;
	top:15px;
}
.teaser-img {
	min-height:210px;
	background-repeat:no-repeat;
	background-position:center;
	background-size:cover;
	position:relative;
}

@media(max-width:991px) {
	.teaser-img {
		min-height: 45vw;
	}
}

.teaser-img.with-img {
	text-align:center;
	padding:30px;
	display:flex;
	justify-content:center;
	align-items:center;
}
.teaser-img.with-img img {
	max-width:100%;
}

.teaser-cat {
	font-size: 12px;
	font-weight:700;
	color:#ffffff;
	text-transform:uppercase;
	padding:7px 15px;
}

.teaser .teaser-cat {
	position:absolute;
	left:0;
	top:20px;
}

.teaser-content {
	padding:30px 20px;
	display:flex;
	flex-direction:column
}

@media(max-width:991px) {
	.teaser-content {
		height:auto !important;
	}
}

.teaser a {
	color:inherit;
}

.teaser-link {
	display:table;
	margin:20px auto 0;
	color:#414141;
	font-size:12px;
	font-weight:700;
	text-transform:uppercase;
}
.teaser-link:after {
	content:'➜';
	display:inline-block;
	vertical-align:top;
	font-size:20px;
	line-height:0;
	margin-top:9px;
	margin-left:7px;
	position:relative;
	left:0;
	transition:left 0.2s ease;
}
.teaser-link:hover,
.teaser-link:focus {
	color:inherit;
}
.teaser-link:hover:after,
.teaser-link:focus:after {
	left:3px;
}


.elt-date {
	color:#ababab;
	font-weight:600;
}

.teaser-actu {
	position:relative;
}

.teaser-actu .bloc-text {
	margin-bottom:20px;
}

.teaser-actu .elt-date {
	margin:0 0 10px;
}

.teaser-actu .teaser-link {
	margin:auto 0 0;
	padding-top:20px;
}


.teaser-status {
	margin: 0 0 20px;
    font-weight: 600;
}

.section-main-news {
	background-color:#ffffff;
	margin-left:-30px;
	margin-right:-30px;
	padding:30px 30px 45px;
	position:relative;
	box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.05);
}

.section-main-news .btn-more {
	position:absolute;
	left:50%;
	margin-left:-17px;
	bottom:-15px;
	transition:all 0.3s ease;
}

.main-news-inner .bloc-text {
	max-height:70px;
	overflow:hidden;
	transition:all 0.5s ease-out;
}

.section-main-news.open .main-news-inner .bloc-text {
	max-height:1000px;
	transition:all 0.8s ease;
}

.section-main-news.open .btn-more {
	transform:rotate(45deg);
}

.section-page-list .teaser {
	/*max-width:360px;*/
}


/*** BLOC INDISPENSABLES ***/
.close-section {
	border:none;
	background:none;
	font-size:12px;
	float:right;
	margin-bottom:10px;
	outline:0;
}
.close-section:after {
	content:'';
	width: 25px;
	height: 25px;
	border-radius:100%;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.22);
	background-color: #c1c1c1;
	background:url('../img/icons/icon-close-blanc.svg') no-repeat center #c1c1c1;
	display:inline-block;
	vertical-align:middle;
	margin-left:10px;
}
.title-6 + .close-section {
	margin:-45px 0 0;
}


.bloc-essentiel {
	text-align:center;
	margin:10px 0 20px;
	background-color:#ffffff;
	border-radius: 2px;
  	box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.05);
}
.bloc-essentiel > a {
	color:inherit;
	display:block;
	padding:30px 20px;
}

.essentiel-img {
	margin-bottom:20px;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
}

@media(max-width:991px) {
	.essentiel-img,
	.essentiel-content {
		height:auto !important;
	}
}


/*** Teaser opé ***/
.teaser-ope {
	margin:10px 0 30px;
	background-color:#ffffff;
	box-shadow: 0 0 8px 3px rgba(0, 0, 0, 0.04);
}
.teaser-ope-content {
	padding:30px;
}
@media(max-width:580px) {
	.teaser-ope-content {
		padding:30px 15px;
	}
}

@media(max-width:991px) {
	.teaser-ope .animation-info {
		height:auto !important;
	}
}

.teaser-ope .animation-head-wrap {
	padding-bottom:0;
}

.table-dashboard {
	margin-bottom:40px;
}

.table-dashboard.table-custom > thead > tr > th:last-child,
.table-dashboard.table-custom > tbody > tr > td:last-child {
	border-left:0;
}


.teaser-command-list {
	margin:10px 0 40px;
}

@media(max-width:767px) {
	.teaser-command-list {
		margin:10px 0 20px;
	}
}

.teaser-command {
	min-height: 150px;
	display:flex;
	justify-content:center;
	align-items: center;
  	border-radius: 3px;
  	color:#ffffff;
  	font-size:22px;
  	line-height: 1.18;
  	padding:20px 40px;
}
.teaser-command > span {
	font-size:70px;
	font-weight:600;
	line-height: 0.84;
	margin-right:15px;
}

@media(max-width:1199px) {
	.teaser-command > span {
		font-size:50px;
	}
}

@media(max-width:991px) {
	.section-commands .table-rwd-2 {
		margin:0 0;
		box-shadow:none;
	}
	.section-commands .table-rwd-2 tr {
		margin-bottom:50px;
		box-shadow:0 0 8px 3px rgba(0, 0, 0, 0.04);
	}

	.teaser-command {
		margin:0 0 20px;
	}
}

@media(max-width:580px) {
	.teaser-command {
		display:block;
		text-align:center;
		min-height:0;
		padding:30px;
		font-size:18px;
	}

	.teaser-command > span {
		display:block;
		margin:0 0 10px;
		font-size:45px;
	}
}